import React, {FC, useEffect} from "react";
import {useFormik} from "formik";
import {Alert, Box, Button, SelectChangeEvent, Tooltip} from "@mui/material";
import {FieldSet} from "../../../components/form/FieldSet";
import {SelectField} from "../../../components/form/SelectField";
import {TextField} from "../../../components/form/TextField";
import {CheckboxField} from "../../../components/form/CheckboxField";
import FormValueHelper from "../../../helpers/FormValueHelper";
import {MoneyField} from "../../../components/form/MoneyField";
import {CalculationQuestionOptionTable} from "./CalculationQuestionOptionTable";
import {useGetOptionGroupsQuery} from "../../../redux/api/option-groups";
import {useGetMarginGroupsQuery} from "../../../redux/api/margin-groups";
import {AutocompleteMultipleField} from "../../../components/form/AutocompleteMultipleField";
import {OptionGroupResponse} from "../../../types/api/responses/option-group/OptionGroupResponse";
import {AppDialog} from "../../../components/dialogs/AppDialog";
import ArrayHelper from "../../../helpers/ArrayHelper";
import {
    CalculationQuestionOptionResponse
} from "../../../types/api/responses/calculation/CalculationQuestionOptionResponse";
import {CmsBlockField} from "../../../components/form/CmsBlockField";

export type QuestionFormProps = {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    fieldPrefix: string,
    isShared: boolean,
    showSharedCheckbox: boolean,
    open?: boolean
}

export const QuestionForm: FC<QuestionFormProps> = ({fieldPrefix, open = true, formik, isShared, showSharedCheckbox}) => {
    const [selectedOptionGroup, setSelectedOptionGroup] = React.useState<OptionGroupResponse | null>(null);
    const [questionFieldDisabled, setQuestionFieldDisabled] = React.useState<boolean>(false);
    const {data: optionGroups} = useGetOptionGroupsQuery({page: 1, pageSize: 1000, filter: [{field: 'active', value: '1'}]});
    const {data: marginGroups} = useGetMarginGroupsQuery({page: 1, pageSize: 1000});
    const questionMapping: { [key: string]: {value: string, isDisabled: boolean} } = {
        "generic": {"value": "", "isDisabled": false},
        "print_position": {"value": "Kies je drukpositie", "isDisabled": true},
        "amount": {"value": "Kies je aantal", "isDisabled": true},
        "color": {"value": "Kies je kleur", "isDisabled": true},
        "print_color": {"value": "Kies je bedrukking", "isDisabled": true},
    };

    useEffect(() => {
        if (formik.values[`${fieldPrefix}questionType`]) {
            const selectedQuestionTypeValue = formik.values[`${fieldPrefix}questionType`];
            let question = questionMapping[selectedQuestionTypeValue].value;
            if (question !== "" && !question) return;

            setQuestionFieldDisabled(questionMapping[selectedQuestionTypeValue].isDisabled);
        }
    }, []);

    useEffect(() => {
        if (formik.values[`${fieldPrefix}questionType`] === 'amount') {
            formik.setFieldValue(`${fieldPrefix}viewType`, 'amount');
        }

        if (ArrayHelper.get(formik.values, `${fieldPrefix}questionType`, '') === 'amount') {
            formik.setFieldValue(`${fieldPrefix}viewType`, 'amount');
        }

    }, [formik.values]);

    /**
     * @param optionGroup
     */
    const onAddOptionGroup = (optionGroup: string) => {
        if (!optionGroups) return; // Cant select an option group that isn't loaded

        const selectedOptionGroup = optionGroups['hydra:member'].find(group => group['@id'] === optionGroup);
        if (!selectedOptionGroup) return; // Selected invalid optiongroup?

        setSelectedOptionGroup(selectedOptionGroup);
    }

    /**
     * Add the options of the selected option group to the question
     */
    const onAddOptionGroupOptions = () => {
        if (!selectedOptionGroup) return;

        const currentOptions = ArrayHelper.get(formik.values, `${fieldPrefix}options`, []);
        let optionGroupOptions: Array<CalculationQuestionOptionResponse> = [];
        selectedOptionGroup.options.forEach(option => {
            const newOption= structuredClone(option);
            newOption.parent = option['@id'];
            // @ts-ignore
            delete newOption['@id'];
            delete newOption.id;

            for (let pr = 0; pr < newOption.priceTiers.length; pr++) {
                delete newOption.priceTiers[pr].id;
                newOption.priceTiers[pr].parent = newOption.priceTiers[pr]['@id'];
                delete newOption.priceTiers[pr]['@id'];
            }
            optionGroupOptions.push(newOption);
        });

        formik.setFieldValue(`${fieldPrefix}options`, [...currentOptions, ...optionGroupOptions]);
        setSelectedOptionGroup(null);
    }

    /**
     * Replace the options of the question with the options of the selected option group
     */
    const onReplaceWithOptionGroupOptions = () => {
        if (!selectedOptionGroup) return;

        let optionGroupOptions: Array<CalculationQuestionOptionResponse> = [];
        selectedOptionGroup.options.forEach(option => {
            const newOption= structuredClone(option);
            newOption.parent = option['@id'];
            // @ts-ignore
            delete newOption['@id'];
            delete newOption.id;

            for (let pr = 0; pr < newOption.priceTiers.length; pr++) {
                delete newOption.priceTiers[pr].id;
                newOption.priceTiers[pr].parent = newOption.priceTiers[pr]['@id'];
                delete newOption.priceTiers[pr]['@id'];
            }
            optionGroupOptions.push(newOption);
        });

        formik.setFieldValue(`${fieldPrefix}options`, optionGroupOptions);
        setSelectedOptionGroup(null);
    }

    return (
        <>
            {isShared && (
                <Alert color={'warning'}>
                    Dit is een gedeelde vraag. Wijzigingen kunnen worden doorgevoerd via het Calculatievragen beheer.
                </Alert>
            )}
            <Box sx={{display: 'flex', flexDirection: 'row', gap: 4}}>
                <FieldSet variant="outlined" title="Algemeen">
                    <SelectField
                        formik={formik}
                        disabled={isShared}
                        options={[
                            {label: 'Algemeen', value: 'generic'},
                            {label: 'Drukpositie', value: 'print_position'},
                            {label: 'Aantallen', value: 'amount'},
                            {label: 'Kleuren', value: 'color'},
                            {label: 'Drukkleuren', value: 'print_color'},
                        ]}
                        name={`${fieldPrefix}questionType`}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                            const selectedQuestionTypeValue = e.target.value as string;
                            if (!selectedQuestionTypeValue) return;

                            let question = questionMapping[selectedQuestionTypeValue].value;
                            if (question !== "" && !question) return;

                            formik.setFieldValue(`${fieldPrefix}question`, question);
                            // disable or enable the question field
                            setQuestionFieldDisabled(questionMapping[selectedQuestionTypeValue].isDisabled);
                        }}
                        label={"Vraag type"} />
                    <TextField
                        required
                        fullWidth
                        disabled={isShared || questionFieldDisabled}
                        name={`${fieldPrefix}question`}
                        formik={formik}
                        label={"Vraag"} />
                    {optionGroups && (
                        <AutocompleteMultipleField
                            disabled={isShared}
                            options={optionGroups['hydra:member'].map(optionGroup => ({value: optionGroup['@id'], label: optionGroup.sku === '' ? optionGroup.name : optionGroup.name + ' (' + optionGroup.sku + ')' }))}
                            formik={formik}
                            name={`${fieldPrefix}optionGroups`}
                            label="Optie groep"
                            onAdd={option => onAddOptionGroup(option.value)}
                            removeConfirmMessage={'<p>Weet je zeker dat je deze optiegroep wilt verwijderen?</p><p><b>Let op:</b> je dient zelf de opties hieronder handmatig te verwijderen.</p>'}
                        />
                    )}
                    <TextField
                        required={ArrayHelper.get(formik.values, `${fieldPrefix}questionType`, '') === 'color'}
                        fullWidth
                        disabled={isShared}
                        name={`${fieldPrefix}mainSku`}
                        formik={formik}
                        label={"Hoofd SKU"} />
                    <Tooltip title={'Geeft aan of deze vraag wordt weergegeven in de webshop'}>
                        <span>
                            <CheckboxField
                                formik={formik}
                                label={'Actief'}
                                name={`${fieldPrefix}active`} />
                        </span>
                    </Tooltip>
                    <Tooltip title={'Geeft aan of deze vraag beantwoordt moet worden per drukpositie'}>
                        <span>
                            <CheckboxField
                                formik={formik}
                                label={'Antwoord per drukpositie'}
                                name={`${fieldPrefix}answerPerPosition`} />
                        </span>
                    </Tooltip>
                    {!isShared && showSharedCheckbox && (
                        <Tooltip title={'Geeft aan of deze vraag een gedeelde vraag is. Gedeelde vragen kun je hergebruiken bij andere producten.'}>
                            <span>
                                <CheckboxField
                                    formik={formik}
                                    label={'Gedeeld'}
                                    name={`${fieldPrefix}shared`} />
                            </span>
                        </Tooltip>
                    )}

                    <Tooltip title={'De geselecteerde optie van deze vraag bepaald de SKU van het hoofdproduct die naar Exact wordt gestuurd'}>
                        <span>
                            <CheckboxField
                                formik={formik}
                                disabled={isShared}
                                label={'Bepaald bestelde SKU'}
                                name={`${fieldPrefix}determinesOrderedSku`} />
                        </span>
                    </Tooltip>
                </FieldSet>

                <FieldSet variant="outlined" title="Webshop">
                    <CmsBlockField
                        fullWidth
                        name={`${fieldPrefix}informationBlockId`}
                        disabled={isShared}
                        formik={formik}
                        label={"Extra Informatie CMS Blok ID"}
                    />
                    <SelectField
                        required
                        formik={formik}
                        disabled={isShared || formik.values[`${fieldPrefix}questionType`] === 'amount' || ArrayHelper.get(formik.values, `${fieldPrefix}questionType`, '') === 'amount'}
                        options={[
                            {value: 'grid', label: 'Raster'},
                            {value: 'checkbox-list', label: 'Vinkjes lijst'},
                            {value: 'radio-list', label: 'Radio lijst'},
                            {value: 'amount', label: 'Aantal'},
                            {value: 'size', label: 'Maat'},
                            {value: 'color', label: 'Kleur'},
                        ]}
                        name={`${fieldPrefix}viewType`}
                        label="Weergave type" />
                    <Tooltip title={'Geeft aan of er voor deze vraag per geselecteerde optie een aantal wordt verwacht'}>
                        <span>
                            <CheckboxField
                                formik={formik}
                                disabled={isShared}
                                label={'Bestel aantal per antwoord'}
                                name={`${fieldPrefix}orderQuantityPerAnswer`} />
                        </span>
                    </Tooltip>
                </FieldSet>
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'row', gap: 4, mt: 2}}>
                <FieldSet variant="outlined" title="Marge instellingen">
                    {marginGroups && (
                        <SelectField
                            formik={formik}
                            disabled={isShared}
                            options={[
                                {label: 'Geen', value: ''},
                                ...marginGroups['hydra:member'].map(marginGroup => ({value: marginGroup['@id'], label: marginGroup.name}))
                            ]}
                            name={`${fieldPrefix}marginGroup`}
                            label="Marge groep" />
                    )}
                    <TextField
                        fullWidth
                        disabled={isShared}
                        name={`${fieldPrefix}margin`}
                        valueParser={FormValueHelper.parseFloat}
                        formik={formik}
                        label={"Marge (indien geen margegroep)"} />
                    <MoneyField formik={formik} name={`${fieldPrefix}minimumMargin`} label={"Minimum marge (indien geen margegroep)"} />
                </FieldSet>
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'row', gap: 4, mt: 2}}>
                <FieldSet variant="outlined" title="Opties">
                    <CalculationQuestionOptionTable
                        open={open}
                        formik={formik}
                        isShared={isShared}
                        questionKey={`${fieldPrefix.replace('.', '')}`}
                        questionOptionsKey={`${fieldPrefix}options`} />
                </FieldSet>
            </Box>

            <AppDialog open={!!selectedOptionGroup} onClose={() => setSelectedOptionGroup(null)} title={"Opties samenvoegen?"}>
                Wil je de opties van deze optiegroep toevoegen aan deze vraag, de opties vervangen met de opties van deze groep of de opties niet toevoegen?
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                    <Button autoFocus onClick={onAddOptionGroupOptions} variant={'contained'} sx={{marginRight: 1}}>Opties toevoegen</Button>
                    <Button autoFocus onClick={onReplaceWithOptionGroupOptions} variant={'contained'} sx={{marginRight: 1}}>Opties vervangen</Button>
                    <Button variant="outlined" onClick={() => setSelectedOptionGroup(null)}>Opties niet toevoegen</Button>
                </Box>
            </AppDialog>
        </>
    );
}
