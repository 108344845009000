import React, {FC, useEffect, useRef, useState} from "react";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../../helpers/ApiErrorHelper";
import NotificationHelper from "../../../helpers/NotificationHelper";
import {
    useCreateCalculationQuestionMutation, useGetQuestionQuery,
    useUpdateCalculationQuestionMutation
} from "../../../redux/api/calculation-questions";
import {CalculationQuestionResponse} from "../../../types/api/responses/calculation/CalculationQuestionResponse";
import {QuestionForm} from "./QuestionForm";
import {useNavigate, useParams} from 'react-router-dom';
import {AppBar, Button, IconButton, Toolbar, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {LoadableContainer} from '../../../components/ui/LoadableContainer';
import {ConfirmDialog} from '../../../components/dialogs/ConfirmDialog';
import {LoadingButton} from '../../../components/form/LoadingButton';
import {Box} from '@mui/system';

export const Question: FC = () => {
    const {questionId} = useParams();
    const {id} = useParams();
    const {data: question, isLoading: isLoadingQuestion, refetch: refetchQuestion} = useGetQuestionQuery(questionId as unknown as number);
    const [createCalculationQuestion, {isLoading: isCreating}] = useCreateCalculationQuestionMutation();
    const [updateCalculationQuestion, {isLoading: isUpdating}] = useUpdateCalculationQuestionMutation();
    const navigate = useNavigate();
    const [confirmOpen, setConfirmOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        let hasError = false;

        try {
            const newValues = {...values};
            if ('calculation' in newValues) {
                delete newValues.calculation;
            }

            newValues.shared = true;

            if (newValues.optionGroup) {
                newValues.optionGroup = newValues.optionGroup.value;
            }

            if (newValues.marginGroup) {
                newValues.marginGroup = newValues.marginGroup.value;
            }

            // Set the sort values
            if (newValues.options) {
                newValues.options = newValues.options.map((option: any, index: number) => {
                    return {...option, sort: index};
                });
            }

            // Submit the form
            if (!newValues['@id']) {
                await createCalculationQuestion(newValues).unwrap();
            } else {
                await updateCalculationQuestion({id: newValues.id, body: newValues}).unwrap();
            }
        } catch (err) {
            hasError = true;
            ApiErrorHelper.processErrors(err, formikHelpers);
            formikHelpers.setSubmitting(false);
            NotificationHelper.showError(`Er is iets misgegaan bij het opslaan van de vraag.`);
        }

        if (!hasError) {
            await refetchQuestion();
            NotificationHelper.showSuccess('De vraag is bijgewerkt.');
            reset();
            navigate(`/calculation/${id}/questions`);
        }

    };

    const formik = useFormik({
        initialValues: question || {} as CalculationQuestionResponse,
        onSubmit: submit,
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false,
        validate: (values) => {
            const errors: typeof values = {} as CalculationQuestionResponse;

            if (typeof values.viewType !== 'string' || values.viewType.length < 1) {
                errors.viewType = 'Selecteer een weergave type.';
            }

            return errors
        }
    });

    const handleConfirmClose = (confirmed: boolean) => {
        setConfirmOpen(false);

        if (confirmed) {
            reset();
            navigate(`/calculation/${id}/questions`);
        }
    };

    const reset = () => {
        const newObject: any = {};
        formik.resetForm({values: newObject});
    }

    const onClose = () => {
        if (formik.dirty) {
            setConfirmOpen(true);
        } else {
            reset();
            navigate(`/calculation/${id}/questions`);
        }
    }

    return (
        <LoadableContainer isLoading={isLoadingQuestion}>
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {question?.question ?? 'Calculatievraag'}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <QuestionForm
                formik={formik}
                fieldPrefix={''}
                isShared={false}
                showSharedCheckbox={false}
            />

            <Box sx={{display: 'flex', justifyContent: 'flex-end', my: 2}}>
                <Button color="primary" onClick={onClose} variant="outlined" sx={{marginRight: 1}}>
                    Annuleren
                </Button>

                <LoadingButton
                    onClick={formik.submitForm}
                    loading={isCreating || isUpdating}
                    disabled={isCreating || isUpdating}
                    autoFocus
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Opslaan
                </LoadingButton>
            </Box>

            { confirmOpen &&
                <ConfirmDialog
                    title="Afsluiten"
                    message="Weet je zeker dat je wilt afsluiten? Niet opgeslagen wijzigingen gaan verloren!"
                    onYes={() => handleConfirmClose(true)}
                    onNo={() => handleConfirmClose(false)}
                />
            }
        </LoadableContainer>
    );
}
