import React, {FC, useRef, useState} from "react";
import {AlertColor, Button, Grid, Typography} from "@mui/material";
import {PrivateLayout} from "../../components/layout/PrivateLayout";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../components/datagrid/DynamicDataGrid";
import {GridColDef} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import {RowActions} from "../../components/datagrid/RowActions";
import {AddButton} from "../../components/form/AddButton";
import {ConfirmDialog} from "../../components/dialogs/ConfirmDialog";
import {
    useBulkActivateCalculationMutation,
    useBulkDeactivateCalculationMutation,
    useDeleteCalculationMutation, useGetCalculationsCollectionQuery,
    useLazyExportCalculcationOptionsQuery,
    useLazyGetCalculationDuplicationNameQuery,
    useLazyGetCalculationDuplicationSkuQuery
} from "../../redux/api/calculations";
import {CalculationResponse} from "../../types/api/responses/calculation/CalculationResponse";
import {CalculationForm} from "./Calculations/CalculationForm";
import {BooleanRenderer} from "../../components/datagrid/renderers/BooleanRenderer";
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import {CalculationQuestionsForm} from "./Calculations/CalculationQuestionsForm";
import {CalculationsFilter} from "./Calculations/CalculationsFilter";
import {RequestFilter} from "../../types/api/requests/DynamicListRequest";
import {useAppSelector} from "../../hooks/useAppSelector";
import {ImportCalculcationOptionsDialog} from "./Calculations/ImportCalculcationOptionsDialog";
import NotificationHelper from "../../helpers/NotificationHelper";
import {DataGridToolbarAction} from '../../components/datagrid/DataGridToolbar';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import useQuery from "../../hooks/useQuery";
import {TaxClassResponse} from "../../types/api/responses/extra/TaxClassResponse";
import {useGetMagento2TaxClassesQuery} from "../../redux/api/extra";
import Image from "../../components/ui/Image";
import {useNavigate} from 'react-router-dom';

export const CalculationsPage: FC<{}> = () => {
    const selectedRows = useAppSelector(state => state.datagrid.selected) as Array<number>;
    const navigate = useNavigate();

    const [formOpen, setFormOpen] = useState(false);
    const [importFormOpen, setImportFormOpen] = useState(false);
    const [confirmData, setConfirmData] = useState<{title: string, message: string, onConfirm: () => void} | null>(null);
    const [selectedForEditing, setSelectedForEditing] = useState<CalculationResponse|null>(null);
    const [selectedForQuestions, setSelectedForQuestions] = useState<CalculationResponse|null>(null);

    const [deleteCalculation] = useDeleteCalculationMutation();
    const [getCalculationDuplicationName] = useLazyGetCalculationDuplicationNameQuery();
    const [getCalculationDuplicationSku] = useLazyGetCalculationDuplicationSkuQuery();
    const [exportCalculationOptions, {isLoading: isExporting}] = useLazyExportCalculcationOptionsQuery();
    const [bulkActivate] = useBulkActivateCalculationMutation();
    const [bulkDeactivate] = useBulkDeactivateCalculationMutation();
    const {data: taxClasses, isLoading: isLoadingTaxClasses} = useGetMagento2TaxClassesQuery();

    const gridRef = useRef<DynamicDataGridHandle|null>(null);
    const [filters, setFilters] = useState<RequestFilter>([]);
    const [data, setData] = useState<CalculationResponse[]>([]);
    const query = useQuery();

    let message = null;
    if (query.has('m')) {
        const messageParts = (query.get('m') as string).split('|');
        message = {severity: messageParts[0] as AlertColor, message: messageParts[1]};

        if (message.severity === 'success') {
            NotificationHelper.showSuccess(message.message);
        } else if (message.severity === 'error') {
            NotificationHelper.showError(message.message);
        }
    }


    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1, maxWidth: 100 },
        {
            field: 'image',
            headerName: 'Afb.',
            width: 100,
            renderCell: (params) => {
                if (!params.row.images || params.row.images.length === 0) {
                    return <BrokenImageOutlinedIcon color="secondary" sx={{width: 50, height: 50}}/>;
                }

                return <Image src={params.row.images[0].url} alt={params.row.name} width={50} height={50} />;
            }
        },
        {
            field: 'sku',
            headerName: 'SKU',
            flex: 1,
        },
        {
            field: 'name',
            headerName: 'Naam',
            minWidth: 400,
            flex: 1,
        },
        {
            field: 'active',
            headerName: 'Actief',
            flex: 1,
            renderCell: (params) => {
                return <BooleanRenderer value={params.row.active} />;
            }
        },
        {
            field: 'inStock',
            headerName: 'Voorraad',
            flex: 1,
            renderCell: (params) => {
                return <BooleanRenderer value={params.row.inStock} />;
            }
        },
        {
            field: 'taxGroup',
            headerName: 'BTW groep',
            flex: 1,
            maxWidth: 100,
            renderCell: (params) => {
                return taxClasses?.taxClasses.find((tc: TaxClassResponse) =>
                    tc.class_id === parseInt(params.row.taxGroup))?.class_name ?? '-';
            }
        },
        {
            field: 'actions',
            headerName: 'Acties',
            flex: 1,
            renderCell: (params) => {
                const actions = [
                    {
                        icon: <EditIcon/>,
                        label: 'Wijzigen',
                        onClick: () => {
                            setSelectedForEditing(params.row);
                            setFormOpen(true);
                        }
                    },
                    {
                        icon: <ContentCopyOutlinedIcon/>,
                        label: 'Dupliceren',
                        onClick: async () => {
                            const clone = JSON.parse(JSON.stringify(params.row));

                            clone.id = null;
                            clone['@id'] = null;
                            clone.name = await getCalculationDuplicationName(clone.name).unwrap();
                            // clone.sku = await getCalculationDuplicationSku(clone.sku).unwrap();
                            clone.sku = '';
                            clone.activatable = false;
                            clone.active = false;

                            clone.attributes = clone.attributes.map((attribute: any) => {
                                attribute.id = null;
                                attribute['@id'] = null;

                                return attribute;
                            });

                            clone.questions = clone.questions.map((question: any) => {
                                question.id = null;
                                question['@id'] = null;
                                delete question.calculation;

                                if (question.questionType === 'color') {
                                    question.mainSku = clone.sku;
                                }

                                question.options = question.options.map((option: any) => {
                                    option.id = null;
                                    option['@id'] = null;

                                    option.priceTiers = option.priceTiers?.map((priceTier: any) => {
                                        priceTier.id = null;
                                        priceTier['@id'] = null;

                                        return priceTier;
                                    }) ?? [];

                                    return option;
                                });

                                return question;
                            });

                            setSelectedForEditing(clone);
                            setFormOpen(true);
                        }
                    },
                    {
                        icon: <EditIcon/>,
                        label: 'Vragen wijzigen',
                        onClick: () => {
                            navigate(`/calculation/${params.row.id}/questions`)
                            // setSelectedForQuestions(params.row);
                        }
                    },
                    {
                        icon: <DownloadIcon />,
                        label: 'Exporteren',
                        onClick: () => {
                            downloadCalculationOptions([params.row.id]);
                        }
                    },
                ];

                if (!params.row.active && params.row.activatable) {
                    actions.push({
                        icon: <ToggleOnOutlinedIcon/>,
                        label: 'Activeren',
                        onClick: () => setConfirmData({
                            title: 'Calculatie activeren',
                            message: `Weet je zeker dat je calculatie "${params.row.name ?? params.row.id}" wilt activeren?`,
                            onConfirm: async () => {
                                await handleBulkActivate([params.row.id]);
                            }
                        })
                    });
                }

                if (params.row.active) {
                    actions.push({
                        icon: <ToggleOffOutlinedIcon />,
                        label: 'Deactiveren',
                        onClick: () => setConfirmData({
                            title: 'Calculatie deactiveren',
                            message: `Weet je zeker dat je calculatie "${params.row.name ?? params.row.id}" wilt deactiveren?`,
                            onConfirm: async () => {
                                await handleBulkDeactivate([params.row.id]);
                            }
                        })
                    })
                }

                actions.push({
                    icon: <DeleteForeverIcon/>,
                    label: 'Verwijderen',
                    onClick: () => setConfirmData({
                        title: 'Calculatie verwijderen',
                        message: `Weet je zeker dat je calculatie "${params.row.name ?? params.row.id}" wilt verwijderen?`,
                        onConfirm: async () => {
                            await handleDelete(params.row.id);
                        }
                    })
                });

                return <RowActions actions={actions}/>;
            },
        },
    ];

    const bulkActions: DataGridToolbarAction[] = [
        {icon: <DownloadIcon />, label: 'Exporteren', onClick: () => downloadCalculationOptions(selectedRows)},
    ];


    const selectedActivatableCalculations = selectedRows.filter(r => data.find(d => d.id === r)?.activatable);

    if (selectedActivatableCalculations.length === selectedRows.length) {
        bulkActions.push({
            icon: <ToggleOnOutlinedIcon />,
            label: 'Activeren',
            onClick: () => setConfirmData({
                title: 'Geselecteerde calculaties activeren',
                message: 'Weet je zeker dat je de '+selectedRows.length+' calculaties wilt activeren?',
                onConfirm: () => handleBulkActivate(selectedRows)
            })
        });
    }

    bulkActions.push({
        icon: <ToggleOffOutlinedIcon />,
        label: 'Deactiveren',
        onClick: () => setConfirmData({
            title: 'Geselecteerde calculaties deactiveren',
            message: 'Weet je zeker dat je de '+selectedRows.length+' calculaties wilt deactiveren?',
            onConfirm: () => handleBulkDeactivate(selectedRows)
        }),
    });

    const downloadCalculationOptions = async (ids: Array<number>) => {
        const response = await exportCalculationOptions(ids).unwrap();
        if (response.url) {
            window.open(response.url);
        }
    }

    const handleCreateClick = () => {
        setSelectedForEditing(null);
        setFormOpen(true);
    }

    const handleCloseFormDialog = () => {
        setFormOpen(false);
        gridRef.current?.reload();
    }

    const handleCloseQuestionFormDialog = () => {
        setSelectedForQuestions(null);

        // fix race condition... 🤮
        setTimeout(() => {
           gridRef.current?.reload();
        }, 2000);
    }

    const handleDelete = async (id: number) => {
        try {
            await deleteCalculation(id).unwrap();
            setConfirmData(null);
            gridRef.current?.reload();
            NotificationHelper.showSuccess('De geselecteerde calculaties zijn verwijderd.');
        } catch (e) {
            NotificationHelper.showError('Er is een fout opgetreden bij het verwijderen van de calculaties. Probeer het later nog eens.');
        }
    }

    const handleBulkActivate = async (ids: Array<number>) => {
        try {
            await bulkActivate(ids).unwrap();
            gridRef.current?.reload();
            setConfirmData(null);
            NotificationHelper.showSuccess('De geselecteerde calculaties zijn geactiveerd.');
        } catch (e) {
            NotificationHelper.showError('Er is een fout opgetreden bij het activeren van de calculaties. Probeer het later nog eens.');
        }
    }

    const handleBulkDeactivate = async (ids: Array<number>) => {
        try {
            await bulkDeactivate(ids).unwrap();
            gridRef.current?.reload();
            setConfirmData(null);
            NotificationHelper.showSuccess('De geselecteerde calculaties zijn gedeactiveerd.');
        } catch (e) {
            NotificationHelper.showError('Er is een fout opgetreden bij het deactiveren van de calculaties. Probeer het later nog eens.');
        }
    }

    const handleFollowUpForQuestions = (calculation: CalculationResponse) => {
        setSelectedForQuestions(calculation);
    }

    return (
        <PrivateLayout>
            <Grid container>
                <Grid item xs={12} sm={6} mb={3}>
                    <Typography component="h1" variant="h5">
                        Calculaties
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={'right'}>
                    <Button variant="contained" startIcon={<ImportExportOutlinedIcon />} onClick={() => setImportFormOpen(true)} sx={{marginRight: 2}}>
                        Calculatie opties bijwerken
                    </Button>
                    <AddButton title="Calculatie toevoegen" onClick={handleCreateClick} />
                </Grid>

                <CalculationsFilter filters={filters} onFiltersChanged={setFilters}/>

                <DynamicDataGrid
                    actions={bulkActions}
                    ref={gridRef}
                    filter={filters}
                    columns={columns}
                    queryHook={useGetCalculationsCollectionQuery}
                    autoHeight={true}
                    checkboxSelection={true}
                    disableRowSelectionOnClick={true}
                    onData={setData}
                />
            </Grid>

            <CalculationForm
                action={selectedForEditing?.id ? 'edit' : 'create'}
                open={formOpen}
                close={handleCloseFormDialog}
                initialValues={selectedForEditing}
                followUpForQuestions={handleFollowUpForQuestions}
            />

            {importFormOpen && (
                <ImportCalculcationOptionsDialog close={() => {setImportFormOpen(false); handleCloseFormDialog();} } />
            )}

            {selectedForQuestions && (
                <CalculationQuestionsForm
                    action='edit'
                    open={true}
                    close={handleCloseQuestionFormDialog}
                    calculation={selectedForQuestions as CalculationResponse}
                />
            )}

            {confirmData && (
                <ConfirmDialog
                    title={confirmData.title}
                    message={confirmData.message}
                    onYes={confirmData.onConfirm}
                    onNo={() => setConfirmData(null)}
                />
            )}
        </PrivateLayout>
    );
}
