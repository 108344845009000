import React, {FC, useState} from "react";
import {AppDialog} from "../../../components/dialogs/AppDialog";
import {useFormik} from "formik";
import {Box, Button, useTheme} from "@mui/material";
import ArrayHelper from "../../../helpers/ArrayHelper";
import {RepeatableField} from "../../../components/form/RepeatableField";
import {MoneyField} from "../../../components/form/MoneyField";
import {PercentageField} from "../../../components/form/PercentageField";
import {useGetMarginGroupsQuery} from "../../../redux/api/margin-groups";
import {
    useDeleteCalculationQuestionOptionPriceTierMutation
} from "../../../redux/api/calculation-question-option-price-tiers";
import {useGetHandlingCostGroupQuery} from "../../../redux/api/handling-cost-groups";
import {useGetCalculationQuery} from "../../../redux/api/calculations";
import {useGetSupplierQuery} from "../../../redux/api/suppliers";
import {ConfirmDialog} from '../../../components/dialogs/ConfirmDialog';
import {type} from 'node:os';

export type CalculationOptionPriceTierFormProps = {
    close: () => void,
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    open: boolean,
    questionKey: string,
    optionKey: string,
    isSynced?: boolean
    handlingGroup?: string,
    isOptionGroup?: boolean,
};

export const CalculationOptionPriceTierForm: FC<CalculationOptionPriceTierFormProps> = ({
    close,
    open,
    formik,
    questionKey,
    optionKey,
    isSynced,
    handlingGroup,
    isOptionGroup = false
}) => {
    const [deletePriceTier] = useDeleteCalculationQuestionOptionPriceTierMutation();
    const theme = useTheme();
    const {data: marginGroups} = useGetMarginGroupsQuery({});
    const {data: optionGroup} = useGetHandlingCostGroupQuery(handlingGroup || '');
    const questionData = ArrayHelper.get(formik.values, questionKey, {});
    const optionData = ArrayHelper.get(formik.values, optionKey, {});
    const minimumMarginAmount = ArrayHelper.get(questionData, 'minimumMargin.amount', -1);
    const {data: calculation} = useGetCalculationQuery(questionData.calculation);
    const {data: supplier} = useGetSupplierQuery(questionData?.calculation?.supplier || "");
    const [confirmSync, setConfirmSync] = useState<{title: string, message: string, onConfirm: () => void, onDecline: () => void} | null>(null);
    const optionNumber = parseInt(optionKey.split('.').pop() || '0');

    const handlePriceTierRemove = (index: number) => {
        const priceTierToRemove = ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${index}`);
        if (!priceTierToRemove) {
            return;
        }

        deletePriceTier(priceTierToRemove.id);
    }

    const calculateMargin = async (row: number, field: string, newValue?: any) => {
        const priceTiers = ArrayHelper.get(formik.values, `${optionKey}.priceTiers`, []);

        priceTiers.forEach((priceTier: any, index: number) => {
            if (index !== row) {
                return;
            }


            const priceTierKey = `${optionKey}.priceTiers.${index}`;
            const quantity = parseInt(ArrayHelper.get(formik.values, `${priceTierKey}.quantity`, 0)) || 0;
            let setupCost;
            let purchasePrice;

            if (field === 'setupCost' && typeof newValue !== 'undefined' && newValue !== null) {
                setupCost =  typeof newValue.amount !== 'undefined' ? parseFloat(newValue.amount) : parseFloat(newValue);
            } else {
                setupCost = parseFloat(ArrayHelper.get(formik.values, `${priceTierKey}.setupCost.amount`, null));
            }

            if (field === 'purchasePrice' && typeof newValue !== 'undefined' && newValue !== null) {
                purchasePrice = typeof newValue.amount !== 'undefined' ? parseFloat(newValue.amount) : parseFloat(newValue);
            } else {
                purchasePrice = parseFloat(ArrayHelper.get(formik.values, `${priceTierKey}.purchasePrice.amount`, null));
            }

            // Controleer of setupCost of purchasePrice null is
            if (setupCost === null && purchasePrice === null) {
                console.warn(`Skipping calculation for priceTier ${index} because setupCost or purchasePrice is null.`);
                return;
            }

            setupCost = setupCost || 0;
            purchasePrice = purchasePrice || 0;

            let price = parseFloat(ArrayHelper.get(formik.values, `${priceTierKey}.price.amount`, 0)) || 0;
            let marginPercentage;

            if (field === 'marginPercentage' && typeof newValue !== 'undefined') {
                marginPercentage = newValue;
            } else {
                marginPercentage = parseFloat(ArrayHelper.get(formik.values, `${priceTierKey}.marginPercentage`, 0)) || 0
            }


            // Bereken de toeslag p/s (bonusCost) en de verzendkosten (postageCost)
            let bonusCost = handlingGroup && optionGroup ? getBonus(row) || 0 : 0;
            let postageCost = supplier && questionData.questionType === "amount" ? getPostageCost(row, purchasePrice) || 0 : 0;

            // Bereken de prijs p/s inclusief toeslag en verzendkosten
            const pricePerItemWithPostage = Math.round((purchasePrice + (setupCost / quantity) + (postageCost / quantity) + bonusCost) * 100) / 100;
            formik.setFieldValue(`${priceTierKey}.purchasePricePerItemWithPostage`, {
                '@type': 'Money',
                amount: pricePerItemWithPostage,
                "currency": "EUR"
            });

            // Zorg ervoor dat de toeslag direct bij de prijs p/s wordt opgeteld bij het laden
            if (field === 'quantity' || field === 'setupCost' || field === 'purchasePrice' || field === 'marginPercentage' || field === 'init') {
                if (!isNaN(marginPercentage) && marginPercentage > 0 && quantity > 0) {
                    price = Math.ceil((purchasePrice + (setupCost / quantity) + (postageCost / quantity) + bonusCost) / (1 - (marginPercentage / 100)) * 100) / 100;

                    formik.setFieldValue(`${priceTierKey}.price`, {
                        '@type': 'Money',
                        amount: price,
                        "currency": "EUR"
                    });
                }
            } else if (field === 'price' || field === 'init') {
                if (!isNaN(price) && price > 0 && quantity > 0) {
                    marginPercentage = Math.round((1 - ((purchasePrice + (setupCost / quantity) + (postageCost / quantity) + bonusCost) / price)) * 100 * 10) / 10;
                    formik.setFieldValue(`${priceTierKey}.marginPercentage`, marginPercentage);
                }
            }

            // Recalculate other dependent fields
            if (quantity > 0) {
                formik.setFieldValue(`${priceTierKey}.purchasePricePerItem`, {
                    '@type': 'Money',
                    amount: Math.round((purchasePrice + (setupCost / quantity) + bonusCost) * 100) / 100,
                    "currency": "EUR"
                });

                formik.setFieldValue(`${priceTierKey}.purchasePricePerItemExclBonus`, {
                    '@type': 'Money',
                    amount: Math.round((purchasePrice + (setupCost / quantity)) * 100) / 100,
                    "currency": "EUR"
                });

                formik.setFieldValue(`${priceTierKey}.margin`, {
                    '@type': 'Money',
                    amount: Math.round(((purchasePrice + (setupCost / quantity) + (postageCost / quantity) + bonusCost) / (1 - (marginPercentage / 100)) * 0.3) * 100) / 100,
                    "currency": "EUR"
                });
            }
        });
    };

    const getBonus = (row: number) => {
        if (optionGroup) {
            const quantity = ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.quantity`, 0);
            const tiers = optionGroup?.handlingCostGroupTiers ?? [];
            const sortedTiers = [...tiers].sort((a, b) => b.quantity - a.quantity);

            for (const tier of sortedTiers) {
                if (tier.quantity <= quantity) {
                    return tier?.handlingCostPerItem?.amount;
                }
            }
            return 0;
        }
    };

    const getPostageCost = (row: number, purchasePrice?: number) => {
        if (questionData.questionType === "amount" && supplier) {
            const quantity = ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.quantity`, 0);
            const price = purchasePrice ?? ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.purchasePrice.amount`, 0);

            if ( typeof supplier.noShippingCostsFrom?.amount === 'undefined' || supplier.noShippingCostsFrom?.amount > (price * quantity)) {
                return supplier.shippingCosts.amount;
            }
            return 0;
        }
    };

    const getTotal = (row: number) => {
        const quantity = ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.quantity`, 0);
        const price = optionData?.priceTiers[row]?.price?.amount;

        if (quantity > 0 && price !== undefined) {
            return Math.round((quantity * price) * 100) / 100;
        }

        return 0;
    }

    const syncDataFromParent = async(field: string) => {
        if (!formik.values.options[optionNumber].parent) {
            return;
        }

        const currentValue = ArrayHelper.get(formik.values, `${optionKey}.sync${field.charAt(0).toUpperCase() + field.slice(1)}`);

        if (currentValue !== true) {
            setConfirmSync({
                title: 'Weet u zeker dat u door wilt gaan?',
                message: 'Er kunnen afwijkende gegevens ingevoerd zijn voor deze optie, de huidige data gaat verloren als u doorgaat!',
                onConfirm: () => {
                    const parentOption = ArrayHelper.get(formik.values, `${optionKey}.parent`, {});
                    const parentPriceTiers = parentOption?.priceTiers || [];
                    const currentPriceTiers = ArrayHelper.get(formik.values, `${optionKey}.priceTiers`, []);

                    currentPriceTiers.forEach((tier: any, index: number) => {
                        const parentTier = parentPriceTiers[index] || {};
                        formik.setFieldValue(`${optionKey}.priceTiers.${index}.${field}`, parentTier[field]);
                        calculateMargin(index, field, parentTier[field]);
                    });

                    formik.setFieldValue(`${optionKey}.sync${field.charAt(0).toUpperCase() + field.slice(1)}`, true);
                    setConfirmSync(null);
                },
                onDecline: () => {
                    setConfirmSync(null);
                    return;
                }
            });
        } else {
            formik.setFieldValue(`${optionKey}.sync${field.charAt(0).toUpperCase() + field.slice(1)}`, false);
        }
    }

    return (
        <AppDialog
            open={open}
            onClose={close}
            title={`Staffelprijzen voor optie "${optionData.description || 'Nieuwe optie'}" ${questionData.question ? `van "${questionData.question}"` : ''}`}
            fullScreen={false}
            maxWidth={"xl"}
            fullWidth={true}
        >
            <div style={{marginBottom: theme.spacing(4)}}>
                Stel hieronder de staffelprijzen in voor de optie <strong>{optionData.description}</strong> van de
                vraag <strong>{questionData.question}</strong>.
            </div>

            <RepeatableField
                baseName={`${optionKey}.priceTiers`}
                optionName={optionKey}
                formik={formik}
                disabled={isSynced}
                onRemove={handlePriceTierRemove}
                enableSync={!isOptionGroup && formik.values.optionGroups.length > 0}
                columns={[
                    {
                        label: 'Vanaf aantal',
                        name: 'quantity',
                        color: 'white',
                        field: 'number',
                        fieldProps: (row) => ({
                            required: true,
                            onChange: () => calculateMargin(row, 'quantity')
                        })
                    },
                    {
                        label: 'Instel kosten (totaal)',
                        name: 'setupCost',
                        color: '#bffdbf',
                        syncDataFromParent: () => syncDataFromParent('setupCost'),
                        field: MoneyField,
                        fieldProps: (row: number) => ({
                            onChange: (event: { target: { value: any; }; }) => calculateMargin(row, 'setupCost', event.target.value),
                            onBlur: () => calculateMargin(row, 'setupCost'),
                            disabled: formik.values.options[optionNumber].syncSetupCost && !isOptionGroup && formik.values.options[optionNumber].parent && formik.values.optionGroups.length > 0,
                        })
                    },
                    {
                        label: 'Inkoopprijs (p/s)',
                        name: 'purchasePrice',
                        color: '#dbf6ff',
                        syncDataFromParent: () => syncDataFromParent('purchasePrice'),
                        field: MoneyField,
                        fieldProps: (row) => ({
                            onChange: (event: { target: { value: any; }; }) => calculateMargin(row, 'purchasePrice', event.target.value),
                            onBlur: () => calculateMargin(row, 'purchasePrice'),
                            disabled: formik.values.options[optionNumber].syncPurchasePrice && !isOptionGroup && formik.values.options[optionNumber].parent && formik.values.optionGroups.length > 0,
                        })
                    },
                    {
                        label: 'Inkoopprijs p/s zonder extra',
                        name: 'purchasePricePerItemExclBonus',
                        color: 'white',
                        field: MoneyField,
                        fieldProps: (row) => ({
                            onBlur: () => calculateMargin(row, 'purchasePricePerItem'),
                            disabled: true
                        })
                    },
                    ...(handlingGroup && optionGroup ? [{
                        label: 'Toeslag p/s',
                        name: 'bonus',
                        color: 'white',
                        field: MoneyField,
                        fieldProps: (row: number) => ({
                            value: getBonus(row),
                            disabled: true
                        })
                    }] : []),
                    ...(handlingGroup && optionGroup ? [{
                        label: 'Prijs p/s + toeslag',
                        name: 'purchasePricePerItem',
                        color: 'white',
                        field: MoneyField,
                        fieldProps: (row: number) => ({
                            disabled: true,
                            onChange: () => calculateMargin(row, 'purchasePricePerItem')
                        })
                    }] : []),
                    ...(supplier && questionData.questionType === "amount" ? [{
                        label: 'Verzendkosten (totaal)',
                        name: 'postageCost',
                        color: 'white',
                        field: MoneyField,
                        fieldProps: (row: number) => ({
                            value: getPostageCost(row),
                            disabled: true
                        })
                    }] : []),
                    ...(supplier && questionData.questionType === "amount" ? [{
                        label: 'Prijs p/s incl verzendkosten',
                        name: 'purchasePricePerItemWithPostage',
                        color: 'white',
                        field: MoneyField,
                        fieldProps: (row: number) => ({
                            onBlur: () => calculateMargin(row, 'purchasePricePerItemWithPostage'),
                            disabled: true
                        })
                    }] : []),
                    {
                        label: 'Marge %',
                        name: 'marginPercentage',
                        color: 'lightyellow',
                        syncDataFromParent: () => syncDataFromParent('marginPercentage'),
                        type: 'number',
                        field: PercentageField,
                        fieldProps: (row) => ({
                            required: true,
                            onChange: (event: { target: { value: any; }; }) => calculateMargin(row, 'marginPercentage', event.target.value),
                            onBlur: () => calculateMargin(row, 'marginPercentage'),
                            disabled: formik.values.options[optionNumber].syncMarginPercentage && !isOptionGroup && formik.values.options[optionNumber].parent && formik.values.optionGroups.length > 0,
                        })
                    },
                    {
                        label: 'Marge EUR',
                        name: 'margin',
                        color: 'white',
                        field: MoneyField,
                        fieldProps: (row) => ({
                            disabled: true,
                            sx: {
                                '& .MuiInput-root': {
                                    padding: '0px 3px',
                                    backgroundColor: ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.margin.amount`, 0) < minimumMarginAmount
                                        ? '#ffc5b8'
                                        : '#efffe6'
                                }
                            }
                        })
                    },
                    {
                        label: 'Prijs (p/s)',
                        name: 'price',
                        color: 'white',
                        field: MoneyField,
                        fieldProps: (row) => ({
                            onChange: (event: { target: { value: any; }; }) => calculateMargin(row, 'price', event.target.value),
                        })
                    },
                    {
                        label: 'Prijs (totaal)',
                        name: 'totalPrice',
                        color: 'white',
                        field: MoneyField,
                        fieldProps: (row) => ({
                            value: getTotal(row),
                            onChange: (event: { target: { value: any; }; }) => calculateMargin(row, 'totalPrice', event.target.value),
                        })
                    },
                ]}
            />
            {confirmSync && (
                <ConfirmDialog
                    title={confirmSync.title}
                    message={confirmSync.message}
                    onYes={confirmSync.onConfirm}
                    onNo={confirmSync.onDecline}
                />
            )}

            <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                <Button color="primary" onClick={() => close()} variant={"contained"}>
                    Akkoord
                </Button>
            </Box>
        </AppDialog>
    );
}
