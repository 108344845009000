import React, {FC, SyntheticEvent, useEffect, useState} from "react";
import {Autocomplete, AutocompleteProps, Checkbox, TextField} from "@mui/material";
import {useFormik} from "formik";
import ArrayHelper from "../../helpers/ArrayHelper";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {ConfirmDialog} from '../dialogs/ConfirmDialog';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type AutocompleteOption = {
    value: string,
    label: string,
}

type AutocompleteMultipleFieldProps = Omit<AutocompleteProps<AutocompleteOption, true, false, true> & {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    name: string,
    label: string,
    options: Array<AutocompleteOption>,
    onAdd?: (option: AutocompleteOption) => void,
    onRemove?: (option: AutocompleteOption) => void,
    removeConfirmMessage?: string | undefined;
}, 'renderInput'>

export const AutocompleteMultipleField: FC<AutocompleteMultipleFieldProps> = ({formik, name, label, options, onAdd, onRemove, removeConfirmMessage, ...restProps}) => {
    const [initialSelection, setInitialSelection] = React.useState<Array<string>>([]);
    let currentValues = ArrayHelper.get(formik.values, name);
    const [confirmData, setConfirmData] = useState<{title: string, message: string, onConfirm: () => void, onDecline: () => void} | null>(null);

    if (currentValues && ! (currentValues instanceof Array)) {
        currentValues = [currentValues];
    }

    useEffect(() => {
        if (0 < options.length) {
            setInitialSelection(
                options
                    .filter((option: AutocompleteOption) => currentValues.includes(option.value))
                    .map((option: AutocompleteOption) => option.value)
            );
        }
    }, [options, currentValues]);

    if (!options || 0 === options.length) {
        return null;
    }

    return (
        <>
            <Autocomplete
                multiple
                disableCloseOnSelect
                options={options.map((option: AutocompleteOption) => option.value)}
                isOptionEqualToValue={(option: string, value: string) => option === value}
                filterSelectedOptions
                getOptionLabel={(option: any) => {
                    if(option.label){
                        return option.label
                    }

                    return options.find((o: any) => o.value === option)?.label;
                }}
                value={currentValues || initialSelection}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {options.find((o: AutocompleteOption) => o.value === option)?.label || ''}
                    </li>
                )}
                onChange={(event: SyntheticEvent, value: (string)[]) => {
                    let removedOptions = initialSelection
                        .filter((option: string) => !value.includes(option))
                        .map(selected => options.find((o: AutocompleteOption | undefined) => o && o.value === selected));

                    if (value) {
                        // Call the onAdd or onRemove callback with the added or removed option as argument when the callbacks are not undefined
                        if (typeof onAdd === 'function') {
                            const addedOptions = value
                                .filter((option) => !initialSelection.includes(option))
                                .map(selected => options.find((o: AutocompleteOption | undefined) => o && o.value === selected));
                            addedOptions.forEach((option) => {option && onAdd(option)});
                        }
                        if (typeof onRemove === 'function') {
                            removedOptions.forEach((option) => {option && onRemove(option)});
                        }
                    }

                    if (removeConfirmMessage && removedOptions.length > 0) {
                        setConfirmData({
                            title: 'Weet je het zeker?',
                            message: removeConfirmMessage,
                            onConfirm: () => {
                                formik.setFieldValue(name, value);
                                setInitialSelection(value);
                                setConfirmData(null);
                            },
                            onDecline: () => {
                                setConfirmData(null);
                            }
                        });
                    } else {
                        formik.setFieldValue(name, value);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={label}
                        placeholder={label}
                    />
                )}
            />
            {confirmData && (
                <ConfirmDialog
                    title={confirmData.title}
                    message={confirmData.message}
                    onYes={confirmData.onConfirm}
                    onNo={confirmData.onDecline}
                    html
                />
            )}
        </>
    );
}
