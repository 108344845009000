import React, {FC, useEffect, useState} from "react";
import {FormDialog} from "../../../components/dialogs/FormDialog";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../../helpers/ApiErrorHelper";
import NotificationHelper from "../../../helpers/NotificationHelper";
import {AppBar, Autocomplete, Button, Grid, IconButton, TextField, Toolbar, Tooltip, Typography,} from "@mui/material";
import {LoadableContainer} from "../../../components/ui/LoadableContainer";
import {
    useCreateCalculationQuestionMutation,
    useDeleteCalculationQuestionMutation,
    useGetCalculationQuestionsQuery,
    useUpdateCalculationQuestionMutation
} from "../../../redux/api/calculation-questions";
import {useGetCalculationQuery, useGetQuestionsForCalculationQuery} from "../../../redux/api/calculations";
import {CalculationResponse} from "../../../types/api/responses/calculation/CalculationResponse";
import {AppAccordion} from "../../../components/ui/AppAccordion";
import {CalculationQuestionResponse} from "../../../types/api/responses/calculation/CalculationQuestionResponse";
import {QuestionForm} from "./QuestionForm";
import {AppDialog} from "../../../components/dialogs/AppDialog";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {AddButton} from "../../../components/form/AddButton";
import {ConfirmDialog} from "../../../components/dialogs/ConfirmDialog";
import {useNavigate, useParams} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '../../../components/form/LoadingButton';
import {Box} from '@mui/system';
import {CalculationQuestionRequest} from '../../../types/api/requests/calculation/CalculationQuestionRequest';

export const CalculationQuestions = () => {
    const {id} = useParams();
    const [createCalculationQuestion, {isLoading: isCreating}] = useCreateCalculationQuestionMutation();
    const [updateCalculationQuestion, {isLoading: isUpdating}] = useUpdateCalculationQuestionMutation();
    const [deleteCalculationQuestion] = useDeleteCalculationQuestionMutation();
    const {data: questions, isLoading: isLoadingQuestions} = useGetQuestionsForCalculationQuery({id: id as unknown as number, filters: {page: 1, pageSize: 1000}});
    const {data: calculation, isLoading: isLoadingCalculation} = useGetCalculationQuery(`/api/calculations/${id}`);
    const {data: sharedQuestions, isLoading: isLoadingSharedQuestions} = useGetCalculationQuestionsQuery({filter: [{field: 'shared', value: 'true'}], page: 1, pageSize: 1000});
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [selectedQuestion, setSelectedQuestion] = React.useState('');
    const [isSaving, setIsSaving] = React.useState(false);
    const [confirmData, setConfirmData] = useState<{title: string, message: string, onConfirm: () => void} | null>(null);
    const navigate = useNavigate();

    const submit = async (values: any, formikHelpers: FormikHelpers<any>, ignoreClose: boolean = false) => {
        let hasError = false;
        setIsSaving(true);

        for(let i = 0; i < values.length; i++) {
            try {
                const newValues = {...values[i]};

                if (newValues.optionGroup) {
                    newValues.optionGroup = newValues.optionGroup.value;
                }

                if (newValues.marginGroup) {
                    newValues.marginGroup = newValues.marginGroup.value;
                }

                newValues.sort = i;

                if (calculation) {
                    newValues.calculation = calculation['@id'];
                }

                // Submit the form
                if (!newValues.id) {
                    await createCalculationQuestion(newValues).unwrap();
                } else {
                    await updateCalculationQuestion({id: newValues.id, body: newValues}).unwrap();
                }
            } catch (err) {
                hasError = true;
                setIsSaving(false);
                ApiErrorHelper.processErrors(err, formikHelpers);
                formikHelpers.setSubmitting(false);
                NotificationHelper.showError(`Er is iets misgegaan bij het opslaan van vraag ${i+1}.`);
            }
        }

        if (!hasError) {
            setIsSaving(false);
            NotificationHelper.showSuccess('De vragen zijn bijgewerkt.');
        }

        if (!ignoreClose) {
            onClose();
        }
    };

    const formik = useFormik({
        initialValues: questions ? questions['hydra:member'] : [],
        onSubmit: submit,
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false,
    });

    const addQuestion = async() => {
        if ('new' === selectedQuestion) {
            await createCalculationQuestion({ question: 'Nieuwe vraag', calculation: `/api/calculations/${id}`, sort: formik.values.length } as CalculationQuestionRequest).unwrap();
        } else {
            if (sharedQuestions === undefined) return;

            const foundQuestion = sharedQuestions['hydra:member'].find(question => question['@id'] === selectedQuestion);
            if (!foundQuestion) return;

            const questionData = structuredClone(foundQuestion);

            // Make sure a copy is created
            questionData.shared = false;
            delete questionData.id;
            questionData.parent = questionData['@id'];
            delete questionData['@id'];

            if (questionData.options && questionData.options.length > 0) {
                for (let o = 0; o < questionData.options.length; o++) {
                    delete questionData.options[o].id;
                    questionData.options[o].parent = questionData.options[o]['@id'];
                    delete questionData.options[o]['@id'];

                    if (!questionData.options[o].priceTiers || 0 === questionData.options[o].priceTiers.length) {
                        continue;
                    }

                    for (let pr = 0; pr < questionData.options[o].priceTiers.length; pr++) {
                        delete questionData.options[o].priceTiers[pr].id;
                        questionData.options[o].priceTiers[pr].parent = questionData.options[o].priceTiers[pr]['@id'];
                        delete questionData.options[o].priceTiers[pr]['@id'];
                    }
                }
            }

            formik.setValues([...formik.values, questionData as CalculationQuestionResponse]);
        }
        setOpenAddDialog(false);
    }

    /**
     * Clone a question
     * @param question
     */
    const cloneQuestion = (question: CalculationQuestionResponse) => {
        const newQuestion = structuredClone(question);

        // Make sure a copy is created
        delete newQuestion.id;
        delete newQuestion['@id'];
        newQuestion.question += ' (kopie)';

        if (newQuestion.options && newQuestion.options.length > 0) {
            for (let o = 0; o < newQuestion.options.length; o++) {
                delete newQuestion.options[o].id;
                delete newQuestion.options[o]['@id'];

                if (!newQuestion.options[o].priceTiers || 0 === newQuestion.options[o].priceTiers.length) {
                    continue;
                }

                for (let pr = 0; pr < newQuestion.options[o].priceTiers.length; pr++) {
                    delete newQuestion.options[o].priceTiers[pr].id;
                    delete newQuestion.options[o].priceTiers[pr]['@id'];
                }
            }
        }

        formik.setValues([...formik.values, newQuestion as CalculationQuestionResponse]);
    }

    const removeQuestion = (index: number) => {
        setConfirmData({
            title: 'Vraag verwijderen',
            message: 'Weet je zeker dat je deze vraag wilt verwijderen? Je kunt deze actie niet ongedaan maken!',
            onConfirm: () => {
                const newValues = [...formik.values];
                const deleted = newValues.splice(index, 1);
                formik.setValues(newValues);

                for(let i = 0; i < deleted.length; i++) {
                    if (deleted[i].id) {
                        deleteCalculationQuestion(deleted[i].id as number);
                    }
                }
                setConfirmData(null);
            }
        });
    }

    const openAddQuestionDialog = () => {
        setOpenAddDialog(true);
    }

    const moveQuestionUp = (index: number) => {
        if (0 === index) return;

        const items = [...formik.values];
        const [reorderedItem] = items.splice(index, 1);
        items.splice(index-1, 0, reorderedItem);
        formik.setValues(items);
    }

    const moveQuestionDown = (index: number) => {
        if (questions && questions['hydra:member'].length-1 === index) return;

        const items = [...formik.values];
        const [reorderedItem] = items.splice(index, 1);
        items.splice(index+1, 0, reorderedItem);
        formik.setValues(items);
    }

    const handleClickQuestion = (questionId: number|null|undefined, key: number) => {
        navigate(`/calculation/${id}/question/${questionId}?key=${key}`)
    }

    const onClose = () => {
        navigate(-1);
    }

    return (
        <>
            <LoadableContainer isLoading={isLoadingQuestions || isLoadingSharedQuestions || isCreating || isUpdating || isSaving || isLoadingCalculation}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {calculation?.name ?? 'Calculatievragen'}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {questions && formik.values.map((question: CalculationQuestionResponse, index) => (
                    <AppAccordion
                        title={`${index+1}. ${question.question}`}
                        key={`question-${index}`}
                        close
                        onClick={() => handleClickQuestion(question.id, index)}
                        headerActions={<>
                            <Tooltip title={"Dupliceren"}>
                                <IconButton size={"small"} onClick={(e) => {e.stopPropagation(); cloneQuestion(question)}}><ContentCopyOutlinedIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title={"Verwijderen"}>
                                <IconButton size={"small"} onClick={(e) => {e.stopPropagation(); removeQuestion(index)}}><DeleteOutlineOutlinedIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title={"Omhoog verplaatsen"}>
                                <span>
                                    <IconButton size={"small"} disabled={index === 0} onClick={(e) => {e.stopPropagation(); moveQuestionUp(index)}}><ArrowUpwardOutlinedIcon /></IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title={"Omlaag verplaatsen"}>
                                <span>
                                    <IconButton size={"small"} disabled={index === questions['hydra:member'].length-1} onClick={(e) => {e.stopPropagation(); moveQuestionDown(index)}}><ArrowDownwardOutlinedIcon /></IconButton>
                                </span>
                            </Tooltip>
                        </>}
                    >
                    </AppAccordion>
                ))}
                <Grid container flexDirection={"row-reverse"} marginTop={2}>
                    <AddButton
                        variant="contained"
                        color="primary"
                        onClick={openAddQuestionDialog}
                        title={'Vraag toevoegen'}
                    />
                </Grid>
            </LoadableContainer>

            <AppDialog open={openAddDialog} onClose={() => setOpenAddDialog(false)} title={'Welke vraag wil je toevoegen?'} fullScreen={false}>
                {sharedQuestions && (
                    <Autocomplete
                        options={[
                            {label: 'Nieuwe vraag', value: 'new'},
                            ...sharedQuestions['hydra:member'].map(question => ({label: question.question, value: question['@id']}) as any)
                        ]}
                        onChange={(e, newValue) => setSelectedQuestion(newValue.value as string)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={'Vraag'}
                                sx={{mb: 2}}
                            />
                        )}
                    />
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={addQuestion}
                >Toevoegen</Button>
            </AppDialog>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', my: 2}}>
                <Button color="primary" onClick={onClose} variant="outlined" sx={{marginRight: 1}}>
                    Annuleren
                </Button>

                <LoadingButton
                    onClick={formik.submitForm}
                    loading={isCreating || isUpdating}
                    disabled={isCreating || isUpdating}
                    autoFocus
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Opslaan
                </LoadingButton>
            </Box>

            {confirmData && (
                <ConfirmDialog
                    title={confirmData.title}
                    message={confirmData.message}
                    onYes={confirmData.onConfirm}
                    onNo={() => setConfirmData(null)}
                />
            )}
        </>
    );
}
