import React, {FC, useEffect, useRef} from "react";
import {FormDialog} from "../../../components/dialogs/FormDialog";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../../helpers/ApiErrorHelper";
import {TextField} from "../../../components/form/TextField";
import NotificationHelper from "../../../helpers/NotificationHelper";
import {useCreateCalculationMutation, useUpdateCalculationMutation} from "../../../redux/api/calculations";
import {Box, Tooltip} from "@mui/material";
import {CheckboxField} from "../../../components/form/CheckboxField";
import {
    AutocompleteMultipleWithNewField,
    AutocompleteOption
} from "../../../components/form/AutocompleteMultipleWithNewField";
import {useCreateCalculationTagMutation, useGetCalculationTagsQuery} from "../../../redux/api/calculation-tags";
import {LoadableContainer} from "../../../components/ui/LoadableContainer";
import {MultiImageUploader} from "../../../components/form/MultiImageUploader";
import {AutocompleteField} from "../../../components/form/AutocompleteField";
import {useGetSuppliersQuery} from "../../../redux/api/suppliers";
import {FieldSet} from "../../../components/form/FieldSet";
import {AppAccordion} from "../../../components/ui/AppAccordion";
import {useGetSettingsQuery} from "../../../redux/api/settings";
import {useGetMagento2TaxClassesQuery} from "../../../redux/api/extra";
import {SelectField} from "../../../components/form/SelectField";
import {CalculationFormAttributes} from "./CalculationFormAttributes";
import {TextFieldWithWysiwygEditor} from "../../../components/form/TextFieldWithWysiwygEditor";
import {CalculationResponse} from "../../../types/api/responses/calculation/CalculationResponse";
import {useNavigate} from 'react-router-dom';

export type CalculationFormProps = {
    action: 'create' | 'edit',
    open: boolean,
    close: () => void,
    initialValues?: any,
    followUpForQuestions?: (calculation: CalculationResponse) => void,
}

export const CalculationForm: FC<CalculationFormProps> = ({action, initialValues, open, close, followUpForQuestions}) => {
    const [createCalculation, {isLoading: isCreating}] = useCreateCalculationMutation();
    const [updateCalculation, {isLoading: isUpdating}] = useUpdateCalculationMutation();
    const [followUp, setFollowUp] = React.useState(false);
    const {data: taxClasses, isLoading: isLoadingTaxClasses} = useGetMagento2TaxClassesQuery();
    const [createCalculationTag] = useCreateCalculationTagMutation();
    const {data: tags, isLoading: isLoadingTags} = useGetCalculationTagsQuery({page: 1, pageSize: 1000});
    const {data: suppliers} = useGetSuppliersQuery({page: 1, pageSize: 1000});
    const {data: settings} = useGetSettingsQuery();
    const dialogRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            dialogRef.current?.querySelector('.MuiPaper-root')?.scrollTo({top: 0});
        })
    }, [open, dialogRef.current]);

    const handleSaveAndFollowUp = (value: boolean) => {
        setFollowUp(value);
    };

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            const newValues = {...values};

            // Normalize the values
            if (newValues.tags && 0 < newValues.tags.length) {
                newValues.tags = newValues.tags.map((tag: AutocompleteOption|string) => {
                    if (typeof tag === 'string') {
                        return tag;
                    }

                    return tag.value;
                });
            }

            if (newValues.supplier && newValues.supplier.value) {
                newValues.supplier = newValues.supplier.value;
            }

            if (newValues.images && 0 < newValues.images.length) {
                newValues.images = newValues.images.map((image: any) => image['@id']);
            }

            if (newValues.attributes) {
                newValues.attributes = newValues.attributes.map((attribute: any) => {
                    if (Array.isArray(attribute.value)) {
                        return {...attribute, value: attribute.value.join(',')};
                    }
                    return attribute;
                });
            }

            if (newValues.taxGroup) {
                newValues.taxGroup = newValues.taxGroup.toString();
            }

            newValues.lotSize = newValues.lotSize === '' ? 0 : parseInt(newValues.lotSize);
            newValues.minimumOrderQuantity = newValues.minimumOrderQuantity === '' ? 0 : parseInt(newValues.minimumOrderQuantity);

            // Submit the form
            if (action === 'create') {
                const newCalculation = await createCalculation(newValues).unwrap();
                NotificationHelper.showSuccess('De calculatie is toegevoegd.');

                if(followUp) {
                    navigate(`/calculation/${newCalculation.id}/questions`);
                }
            } else {
                const calculation = await updateCalculation(newValues).unwrap();
                NotificationHelper.showSuccess('De calculatie is gewijzigd.');

                if(followUp) {
                    navigate(`/calculation/${calculation.id}/questions`);
                }
            }

            formik.resetForm();
            setFollowUp(false);
            close();
        } catch (err) {
            if (err instanceof Object && 'data' in err) {
                const errorData = (err as { data: any }).data;

                if (errorData && errorData['hydra:description']) {
                    NotificationHelper.showError(errorData['hydra:description']);
                } else {
                    ApiErrorHelper.processErrors(err, formikHelpers);
                }
            } else {
                ApiErrorHelper.processErrors(err, formikHelpers);
            }

            formikHelpers.setSubmitting(false);
        }
    };

    // Convert the tags to the correct format
    if (initialValues && initialValues.tags && tags) {
        const selectedTags = initialValues.tags.map((tag: any) => {
            const found = tags['hydra:member'].find((t: any) => t['@id'] === tag);

            // @ts-ignore
            return {value: found['@id'], label: found?.tag};
        });

        initialValues = {...initialValues, tags: selectedTags};
    }

    const formik = useFormik({
        initialValues: initialValues || {lotSize: 1, minimumOrderQuantity: 1},
        onSubmit: submit,
        enableReinitialize: true,
        validate: (values) => {
            const errors: typeof values = {};

            if (typeof values.name !== 'string' || values.name.length < 1) {
                errors.name = 'Naam is verplicht.';
            }

            return errors;
        }
    });

    return (
            <FormDialog
                fullScreen={true}
                open={open}
                close={close}
                formik={formik}
                isSubmitting={isCreating || isUpdating}
                isConceptualizable
                ref={dialogRef}
                handleSubmit={formik.handleSubmit}
                title={`Calculatie ${action === 'create' ? 'toevoegen' : 'aanpassen'}`}
                hasFollowUp={true}
                onFollowUp={handleSaveAndFollowUp}
            >
                <LoadableContainer isLoading={isLoadingTags}>
                    <Box sx={{display: 'flex', flexDirection: 'row', gap: 4}}>
                        <FieldSet variant="outlined" title="Algemeen">
                            <TextField
                                required
                                fullWidth
                                name="name"
                                formik={formik}
                                label={"Naam"} />
                            <TextField
                                required
                                fullWidth
                                disabled={action === 'edit'}
                                name="sku"
                                formik={formik}
                                label={"SKU"} />
                            {suppliers && (
                                <AutocompleteField
                                    options={suppliers['hydra:member'].map(supplier => ({value: supplier['@id'], label: supplier.name}))}
                                    formik={formik}
                                    name="supplier"
                                    label="Leverancier"/>
                            )}
                            <TextField
                                required
                                fullWidth
                                name="supplierSku"
                                formik={formik}
                                label={"SKU (Leverancier)"} />
                            {taxClasses && (
                                <SelectField
                                    required
                                    formik={formik}
                                    options={taxClasses['taxClasses'].filter(taxClass => taxClass.class_type === 'PRODUCT').map(taxClass => ({value: taxClass.class_id, label: taxClass.class_name}))}
                                    name="taxGroup"
                                    label="BTW Groep" />
                            )}
                        </FieldSet>

                        <FieldSet variant="outlined" title="Omschrijving">
                            {/*<TextFieldWithWysiwygEditor*/}
                            {/*    required*/}
                            {/*    fullWidth*/}
                            {/*    name="shortDescription"*/}
                            {/*    formik={formik}*/}
                            {/*    label={"Korte omschrijving"} />*/}
                            <TextFieldWithWysiwygEditor
                                required
                                fullWidth
                                name="longDescription"
                                formik={formik}
                                label={"Lange omschrijving"} />
                            <TextField
                                required
                                fullWidth
                                name="productRange1"
                                formik={formik}
                                label={"Assortiment 1"} />
                            <TextField
                                required
                                fullWidth
                                name="productRange2"
                                formik={formik}
                                label={"Assortiment 2"} />
                        </FieldSet>
                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'row', gap: 4, mt: 2}}>
                        <FieldSet variant="outlined" title="SEO">
                            <TextField
                                required
                                fullWidth
                                name="metaTitle"
                                formik={formik}
                                label={"Meta Titel"} />
                            <TextField
                                required
                                fullWidth
                                name="metaDescription"
                                formik={formik}
                                label={"Meta Omschrijving"} />
                        </FieldSet>

                        <FieldSet variant="outlined" title="Instellingen">
                            <Tooltip title={'Product mag alleen per X besteld worden'}>
                                <span>
                                    <TextField
                                        required
                                        fullWidth
                                        name="lotSize"
                                        formik={formik}
                                        label={"Lot grootte"} />
                                </span>
                            </Tooltip>
                            <Tooltip title={'De minimale bestelhoeveelheid voor dit product'}>
                                <span>
                                    <TextField
                                        required
                                        fullWidth
                                        name="minimumOrderQuantity"
                                        formik={formik}
                                        label={"Minimale bestelhoeveelheid"} />
                                </span>
                            </Tooltip>
                            <Tooltip title={'Bereken de prijs als de bestel hoeveelheid tussen 2 staffels in zit'}>
                                <span>
                                    <CheckboxField
                                        name="calculateIntermediatePrices"
                                        formik={formik}
                                        label={"Bereken tussenprijzen"} />
                                </span>
                            </Tooltip>
                            <Tooltip title={'Dit product is een voorraad product. Als "Op voorraad" uit staat, is dit product niet te bestellen'}>
                                <span>
                                    <CheckboxField
                                        name="stockProduct"
                                        formik={formik}
                                        label={"Voorraad product"} />
                                </span>
                            </Tooltip>
                            <Tooltip title={'Geeft aan of dit product op voorraad is'}>
                                <span>
                                    <CheckboxField
                                        name="inStock"
                                        formik={formik}
                                        label={"Op voorraad"} />
                                </span>
                            </Tooltip>
                            <Tooltip title={'Geeft aan of dit product actief is en besteld kan worden. Vinkje uitzetten om  te kunnen opslaan als concept.'}>
                                <span>
                                    <CheckboxField
                                        name="active"
                                        formik={formik}
                                        label={"Actief"} />
                                </span>
                            </Tooltip>
                        </FieldSet>
                    </Box>

                    {settings && (
                    <Box>
                        <FieldSet variant="outlined" title="Extra gegevens">
                            {settings['webshop/attribute/custom_product_attributes'] && settings['webshop/attribute/custom_product_attributes'].map((attribute: {attribute_code: string, name: string}) => (
                                <TextField
                                    key={attribute.attribute_code}
                                    fullWidth
                                    name={`extraAttributes.${attribute.attribute_code}`}
                                    formik={formik}
                                    label={attribute.name} />
                            ))}
                        </FieldSet>
                    </Box>
                    )}

                    <AppAccordion title={"Afbeeldingen"} sx={{mt: 4}}>
                        <MultiImageUploader formik={formik} label={''} name={'images'} />
                    </AppAccordion>

                    {tags && (
                        <AppAccordion title={"Tags"}>
                            <AutocompleteMultipleWithNewField
                                name="tags"
                                formik={formik}
                                label={"Tags"}
                                options={tags['hydra:member'].map(tag => ({value: tag['@id'], label: tag.tag}))}
                                onNewEntry={async (value) => {
                                    const newTag = await createCalculationTag({tag: value}).unwrap();

                                    return {value: newTag['@id'], label: newTag.tag};
                                }}
                            />
                        </AppAccordion>
                    )}

                    <AppAccordion title={"Attributen"}>
                        <CalculationFormAttributes formik={formik} />
                    </AppAccordion>
                </LoadableContainer>
            </FormDialog>
    );
}
