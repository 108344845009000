import DynamicListRequest from "../types/api/requests/DynamicListRequest";

/**
 * Helper class to build the filtered urls for the API
 */
class UrlHelper
{
    /**
     * @param baseUrl
     * @param requestOptions
     */
    public buildUrl(baseUrl: string, requestOptions: DynamicListRequest): string
    {
        const query: Array<string> = [];

        // Filtering
        if (requestOptions.filter && 0 < requestOptions.filter.length) {
            requestOptions.filter.forEach(filter => {
                if(undefined != filter.value && '' !== filter.value) {
                    query.push(`${filter.field}=${filter.value}`);
                }
            });
        }

        // Search
        if (requestOptions.search) {
            query.push(`dynamicSearch=${requestOptions.search}`);
        }

        // Pagination
        if (requestOptions.page) {
            query.push(`page=${requestOptions.page}`);
        }

        if (requestOptions.pageSize) {
            query.push(`pageSize=${requestOptions.pageSize}`);
        }

        // Sorting
        if (requestOptions.sort && 0 < requestOptions.sort.length) {
            requestOptions.sort.forEach(sort => {
                query.push(`order[${sort.field}]=${sort.sort}`);
            });
        }

        return (0 < query.length ? `${baseUrl}?${query.join('&')}` : baseUrl);
    }
}

export default new UrlHelper() as UrlHelper;
