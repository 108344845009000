import React, {FC} from "react";
import {TextFieldProps as MuiTextFieldProps} from "@mui/material";
import {useFormik} from "formik";
import {TextField} from "./TextField";
import FormValueHelper from "../../helpers/FormValueHelper";

export type PercentageFieldProps = MuiTextFieldProps & {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    name: string,
    type?: string
};

export const PercentageField: FC<PercentageFieldProps> = ({formik, name, type, label, ...restProps}) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = parseFloat(event.target.value);
        if (value > 99) {
            value = 99;
        } else if (value < 0 || isNaN(value)) {
            value = 0;
        }
        formik.setFieldValue(name, value);
    };

    return (
        <TextField
            fullWidth
            name={name}
            formik={formik}
            label={label}
            sx={{minWidth: '80px'}}
            type={type}
            valueParser={FormValueHelper.parseFloat}
            InputProps={{
                endAdornment: '%',
                inputProps: { min: 0, max: 99 }
            }}
            onChange={handleChange}
            {...restProps}
        />
    );
}
