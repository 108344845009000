import {Box, Button, DialogProps} from "@mui/material";
import React, {FC, FormEvent, forwardRef, useEffect, useState} from "react";
import {LoadingButton} from "../form/LoadingButton";
import {AppDialog} from "./AppDialog";
import {ConfirmDialog} from "./ConfirmDialog";
import {useFormik} from 'formik';
import {flushSync} from 'react-dom';

export type FormDialogProps = DialogProps & {
    close: () => void,
    isSubmitting: boolean,
    isConceptualizable?: boolean;
    handleSubmit: (e: FormEvent<HTMLFormElement>) => void,
    title: string,
    formik?: any,
    disableCheck?: boolean,
    hasFollowUp?: boolean,
    onFollowUp?: (value: boolean) => void;
}

export const FormDialog: FC<FormDialogProps> = forwardRef(({children, close, disableCheck, isSubmitting, isConceptualizable, open, handleSubmit, title, fullScreen, formik, hasFollowUp, onFollowUp}, ref) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmCallback, setConfirmCallback] = useState<(() => void) | null>(null)
    const [isSubmittingConcept, setIsSubmittingConcept] = useState(false);

    const handleSaveAndFollowUp = (value: boolean) => {
        if (onFollowUp) {
            onFollowUp(value);
        }
    };

    const handleClose = () => {
        if (!disableCheck && formik.dirty) {
            setConfirmOpen(true);
        } else {
            reset();
            close();
        }
    };

    const handleConfirmClose = (confirmed: boolean) => {
        setConfirmOpen(false);

        if (confirmed) {
            reset();
            close();
        }
    };

    const reset = () => {
        const newObject: any = {};

        for(const key of Object.keys(formik.values)){
            if (formik.values[key] instanceof Array){
                newObject[key] = [];
            }
        }

        formik.resetForm({values: newObject});
    }


    useEffect(() => {
        const handleBeforeUnload = (event: { preventDefault: () => void; returnValue: string; }) => {
            if (!disableCheck && formik?.dirty) {
                event.preventDefault();
                event.returnValue = 'Weet je zeker dat je wilt afsluiten? Niet opgeslagen wijzigingen gaan verloren!';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        const handlePopState = (event: PopStateEvent) => {
            event.preventDefault();
            if (!disableCheck && formik.dirty) {
                setConfirmCallback(() => () => window.history.go(1));
                setConfirmOpen(true);
            } else {
                window.history.back();
            }
        };

        window.addEventListener('popstate', handlePopState);

        // Push a new state to the history stack to catch the back button click
        const pushState = () => {
            window.history.pushState(null, document.title, window.location.href);
        };

        pushState();

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [formik?.dirty]);

    return (
        <>
            <AppDialog ref={ref} open={open} onClose={handleClose} title={title} fullScreen={fullScreen}>
                <form onSubmit={handleSubmit} noValidate={isSubmittingConcept}>
                    {children}
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                        <Button color="primary" onClick={handleClose} variant="outlined" sx={{marginRight: 1}}>
                            Annuleren
                        </Button>

                        {isConceptualizable && ! formik.values.active && (
                            <LoadingButton
                                onClick={() => setIsSubmittingConcept(true)}
                                loading={isSubmitting && isSubmittingConcept}
                                disabled={isSubmitting}
                                color="primary"
                                type="submit"
                                variant="outlined"
                                sx={{marginRight: 1}}
                            >
                                Opslaan als concept
                            </LoadingButton>
                        )}

                        {hasFollowUp && (
                            <LoadingButton
                                onClick={() => handleSaveAndFollowUp(true)}
                                loading={isSubmitting && isSubmittingConcept}
                                disabled={isSubmitting}
                                color="primary"
                                type="submit"
                                variant="outlined"
                                sx={{marginRight: 1}}
                            >
                                Opslaan en door naar vragen
                            </LoadingButton>
                        )}

                        <LoadingButton
                            onClick={() => setIsSubmittingConcept(false)}
                            loading={isSubmitting && !isSubmittingConcept}
                            disabled={isSubmitting && isSubmittingConcept}
                            autoFocus
                            color="primary"
                            type="submit"
                            variant="contained"
                        >
                            Opslaan
                        </LoadingButton>
                    </Box>
                </form>
            </AppDialog>
            { confirmOpen &&
                <ConfirmDialog
                    title="Afsluiten"
                    message="Weet je zeker dat je wilt afsluiten? Niet opgeslagen wijzigingen gaan verloren!"
                    onYes={() => handleConfirmClose(true)}
                    onNo={() => handleConfirmClose(false)}
                />
            }
        </>
    );
})
