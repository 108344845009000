import React, {FC, useEffect, useState} from "react";
import {ButtonProps} from "@mui/material/Button/Button";
import {Box, IconButton, Paper, styled, Typography} from "@mui/material";
import {ImageResponse} from "../../types/api/responses/image/ImageResponse";
import {useFormik} from "formik";
import {TextField} from "./TextField";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {useUpdateImageMutation} from "../../redux/api/images";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import {MultipleImageUploadField} from "./MultipleImageUploadField";

type MultiImageUploaderProps = ButtonProps & {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing,
    name: string,
    label: string,
}

export const MultiImageUploader: FC<MultiImageUploaderProps> = ({formik, name, label}) => {
    const [updateImage] = useUpdateImageMutation();
    const [sortedImages, setSortedImages] = useState<Array<ImageResponse>>([]);

    useEffect(() => {
        if (formik.values[name]) {
            const sorted = [...formik.values[name]].sort((a: ImageResponse, b: ImageResponse) => a.sequence - b.sequence);
            setSortedImages(sorted);
        }
    }, [formik.values[name]]);

    const onImagesUploaded = (images: ImageResponse[], files: File[]) => {
        let currentImages: Array<ImageResponse> = formik.values[name] ? [...formik.values[name]] : [];
        const highestSequence = currentImages.reduce((max, img) => Math.max(max, img.sequence), 0) + 1;
        images.forEach((image, index) => {
            const file = files[index];
            let newImage = {
                ...image,
                altText: file.name.split('.').slice(0, -1).join('.'),
                sequence: highestSequence + index + 1
            };

            currentImages = [...currentImages, newImage];

        });
        formik.setFieldValue(name, currentImages);
        setSortedImages(currentImages.sort((a, b) => a.sequence - b.sequence));
    }


    const onImageRemoved = (imageId: number) => {
        const updatedImages = formik.values[name].filter((img: ImageResponse) => img.id !== imageId);
        formik.setFieldValue(name, updatedImages);
    }

    const onAltTextChanged = async (imageId: number, value: string, sequence?: number) => {
        await updateImage({id: imageId, body: {altText: value, sequence: sequence}}).unwrap();
    };

    const handleMove = async (index: number, direction: 'forward' | 'back') => {
        const swapIndex = direction === 'forward' ? index + 1 : index - 1;

        if (swapIndex >= 0 && swapIndex < sortedImages.length) {
            let images = [...formik.values[name]];

            let currentIndexInFormik = images.findIndex(img => img.id === sortedImages[index].id);
            let swapIndexInFormik = images.findIndex(img => img.id === sortedImages[swapIndex].id);

            if (currentIndexInFormik !== -1 && swapIndexInFormik !== -1) {
                let updatedCurrentImage = {
                    ...images[currentIndexInFormik],
                    sequence: images[swapIndexInFormik].sequence
                };
                let updatedSwapImage = {
                    ...images[swapIndexInFormik],
                    sequence: images[currentIndexInFormik].sequence
                };

                images[currentIndexInFormik] = updatedCurrentImage;
                images[swapIndexInFormik] = updatedSwapImage;

                formik.setFieldValue(name, images);

                await updateImage({ id: updatedCurrentImage.id, body: { sequence: updatedCurrentImage.sequence } }).unwrap();
                await updateImage({ id: updatedSwapImage.id, body: { sequence: updatedSwapImage.sequence } }).unwrap();

                setSortedImages(images.sort((a, b) => a.sequence - b.sequence));
            }
        }
    };

    return (
        <>
            <Typography variant="h6">{label}</Typography>
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 3, mb: 2}}>
                {sortedImages.map((image: ImageResponse, index: number) => {
                    return (
                        <SingleImage key={`image-${image.id}`} variant="outlined">
                            <Box sx={{display: 'flex', justifyContent: 'space-between', position: 'relative', marginBottom: "-25px"}}>
                                <IconButton onClick={() => handleMove(index, 'back')}>
                                    <ArrowBackOutlinedIcon/>
                                </IconButton>
                                <IconButton onClick={() => handleMove(index, 'forward')}>
                                    <ArrowForwardOutlinedIcon/>
                                </IconButton>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 125}}>
                                <Box component={'img'} src={image.url} sx={{maxWidth: 100, maxHeight: 100}} />
                            </Box>
                            <Box sx={{display: 'flex'}}>
                                <TextField
                                    formik={formik}
                                    name={`${name}.${index}.altText`}
                                    value={image.altText}
                                    label="Alt tekst"
                                    onBlur={(e) => onAltTextChanged(image.id, e.target.value)}
                                />
                                <IconButton
                                    onClick={() => onImageRemoved(image.id)}
                                >
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Box>
                        </SingleImage>
                    )}
                )}
            </Box>

            <MultipleImageUploadField onUploaded={onImagesUploaded} />
        </>
    );
}

const SingleImage = styled(Paper)(({ theme }) => ({
    width: 250,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
}));

