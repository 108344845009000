import React, {FC, useState} from 'react';
import {PrivateLayout} from "../../../components/layout/PrivateLayout";
import {Box, Grid, Tab, Tabs, Typography} from "@mui/material";
import {TabPanel} from "../../../components/tabs/TabPanel";
import {FormikHelpers, useFormik} from "formik";
import {useGetSettingsQuery, useSaveSettingsMutation} from "../../../redux/api/settings";
import {LoadableContainer} from "../../../components/ui/LoadableContainer";
import {LoadingButton} from "../../../components/form/LoadingButton";
import ApiErrorHelper from "../../../helpers/ApiErrorHelper";
import {useLocation} from "react-router-dom";
import NotificationHelper from "../../../helpers/NotificationHelper";
import {GeneralForm} from "./Settings/GeneralForm";
import {ExactOnlineForm} from "./Settings/ExactOnlineForm";
import {EmailsForm} from "./Settings/EmailsForm";
import {Magento2Form} from "./Settings/Magento2Form";
import {ApiForm} from "./Settings/ApiForm";
import {DeliveryForm} from "./Settings/DeliveryForm";
import {CutoffForm} from './Settings/CutoffForm';

export const SettingsPage: FC<{}> = () => {
    const location = useLocation();
    const {data: settings, isLoading} = useGetSettingsQuery();
    const [currentTab, setCurrentTab] = useState(location.hash === '#exact' ? 1 : 0);
    const [saveSettings, {isLoading: isSavingSettings}] = useSaveSettingsMutation();

    const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            await saveSettings(values).unwrap();
            NotificationHelper.showSuccess('De instellingen zijn opgeslagen.');
        } catch (e) {
            ApiErrorHelper.processErrors(e, formikHelpers);
        }
    }

    const formik = useFormik({
        initialValues: settings || {},
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    return (
        <PrivateLayout>
            <Grid container>
                <Grid item xs={12} mb={3}>
                    <Typography component="h1" variant="h5">
                        Instellingen beheer
                    </Typography>
                </Grid>
                <LoadableContainer isLoading={isLoading}>
                    <form onSubmit={formik.handleSubmit} style={{width: '100%'}}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'block', width: '100%' }}>
                            <Tabs value={currentTab} onChange={handleTabChange}>
                                <Tab label={"Algemeen"} />
                                <Tab label={"Exact Online"} />
                                <Tab label={"Magento 2"} />
                                <Tab label={"API"} />
                                <Tab label={"Levertijden"} />
                                <Tab label={"Cut-off tijden"} />
                                <Tab label={"E-mails"} />
                            </Tabs>
                        </Box>
                        <TabPanel value={currentTab} index={0}>
                            <GeneralForm formik={formik} />
                        </TabPanel>
                        <TabPanel value={currentTab} index={1}>
                            <ExactOnlineForm formik={formik} />
                        </TabPanel>
                        <TabPanel value={currentTab} index={2}>
                            <Magento2Form formik={formik} />
                        </TabPanel>
                        <TabPanel value={currentTab} index={3}>
                            <ApiForm formik={formik} />
                        </TabPanel>
                        <TabPanel value={currentTab} index={4}>
                            <DeliveryForm formik={formik} />
                        </TabPanel>
                        <TabPanel value={currentTab} index={5}>
                            <CutoffForm formik={formik} />
                        </TabPanel>
                        <TabPanel value={currentTab} index={6}>
                            <EmailsForm formik={formik} />
                        </TabPanel>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                            <LoadingButton loading={isSavingSettings} autoFocus color="primary" type="submit" variant="contained">
                                Opslaan
                            </LoadingButton>
                        </Box>
                    </form>
                </LoadableContainer>
            </Grid>
        </PrivateLayout>
    );
}

