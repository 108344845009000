import React, {FC, useState} from "react";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {TextField} from "./TextField";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {useFormik} from "formik";
import ArrayHelper from "../../helpers/ArrayHelper";

type ColumnProps = {
    label: string,
    name: string,
    type?: string
    field: string|React.FunctionComponent<any>,
    fieldProps?: (row: number) => any,
}

export type RepeatableFieldProps = {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    columns: Array<ColumnProps>,
    baseName: string,
    disabled?: boolean
    required?: boolean
    onRemove?: (index: number) => void,
};

export const RepeatableField: FC<RepeatableFieldProps> = ({formik, columns, baseName, disabled, required, onRemove}) => {
    const [extraRowData, setExtraRowData] = useState({});

    const addExtraRow = () => {
        setExtraRowData({});

        if (!ArrayHelper.get(formik.values, baseName, false)) {
            formik.setFieldValue(baseName, [extraRowData]);
        } else {
            formik.setFieldValue(baseName, [...ArrayHelper.get(formik.values, baseName, false), extraRowData]);
        }

        for(const column of columns) {
            formik.setFieldValue(`ignored_${column.name}`, '');
        }
    }

    const deleteRow = (index: number) => {
        if (onRemove) {
            onRemove(index);
        }

        const newRows = ArrayHelper.get(formik.values, baseName, false).filter((row: any, i: number) => i !== index);
        formik.setFieldValue(baseName, newRows);
    }

    return (
        <TableContainer component={Paper}>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) => (
                            <TableCell key={index}>{column.label}</TableCell>
                        ))}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ArrayHelper.get(formik.values, baseName, false)
                        && ArrayHelper.get(formik.values, baseName, false).map((data: any, index: number) => (
                        <TableRow key={index}>
                            {columns.map((column, i) => (
                                <TableCell key={`${baseName}-${column.name}-${index}`}>
                                    {typeof column.field === "string" && (
                                        <TextField
                                            fullWidth
                                            disabled={disabled}
                                            required={required}
                                            type={column.field}
                                            name={`${baseName}.${index}.${column.name}`}
                                            formik={formik}
                                            label=" " />
                                    )}

                                    {typeof column.field !== "string" && (
                                        <column.field disabled={disabled} required={required} formik={formik} name={`${baseName}.${index}.${column.name}`} type={column.type} label={" "} {...column.fieldProps ? column.fieldProps(index) : {}} />
                                    )}
                                </TableCell>
                            ))}
                            <TableCell>
                                <Tooltip title={"Rij verwijderen"}>
                                    <IconButton onClick={() => deleteRow(index)}>
                                        <DeleteOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        {columns.map((column, index) => (
                            <TableCell key={`${baseName}-${column.name}-new`}>
                                &nbsp;
                            </TableCell>
                        ))}
                        <TableCell>
                            <Tooltip title={"Nieuwe rij toevoegen"}>
                                <IconButton onClick={() => addExtraRow()} onFocus={() => addExtraRow()}>
                                    <AddCircleOutlineOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
