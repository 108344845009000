import React, {FC} from "react";
import {RequestFilter} from "../../../types/api/requests/DynamicListRequest";
import {Filter} from "../../../components/datagrid/Filter";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";

export type OptionGroupsFilterProps = {
    filters: RequestFilter,
    onFiltersChanged: (filters: RequestFilter) => void;
};

let timeout: ReturnType<typeof setTimeout>;

export const OptionGroupsFilter: FC<OptionGroupsFilterProps> = ({filters, onFiltersChanged}) => {
    const addFilter = (field: string, value: string) => {
        if(timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            const newFilters = [];
            filters.forEach(filter => {
                if (filter.field !== field) {
                    newFilters.push(filter);
                }
            });

            newFilters.push({field, value});
            onFiltersChanged(newFilters);
        }, 250);
    };


    return (
        <Filter>
            <TextField
                label="Naam"
                variant="outlined"
                name="filter-name"
                onChange={(e) => addFilter('name', e.target.value)}
                size="small"
            />
            <TextField
                label="SKU"
                variant="outlined"
                name="filter-sku"
                onChange={(e) => addFilter('sku', e.target.value)}
                size="small"
            />
            {/*supplier.name*/}
            <TextField
                label="Leverancier"
                variant="outlined"
                name="filter-supplier"
                onChange={(e) => addFilter('supplier.name', e.target.value)}
                size="small"
            />
            <FormControl variant="outlined" size="small">
                <InputLabel id="filter-active">Actief</InputLabel>
                <Select
                    labelId="filter-active-label"
                    id="filter-active"
                    defaultValue=""
                    onChange={(e: SelectChangeEvent<string>) => addFilter('active', e.target.value)}
                    label="Actief"
                >
                    <MenuItem value=""><em>Alles</em></MenuItem>
                    <MenuItem value="1">Ja</MenuItem>
                    <MenuItem value="0">Nee</MenuItem>
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small">
                <InputLabel id="filter-concept">Concept</InputLabel>
                <Select
                    labelId="filter-concept-label"
                    id="filter-concept"
                    defaultValue=""
                    onChange={(e: SelectChangeEvent<string>) => addFilter('concept', e.target.value)}
                    label="Concept"
                >
                    <MenuItem value=""><em>Alles</em></MenuItem>
                    <MenuItem value="1">Ja</MenuItem>
                    <MenuItem value="0">Nee</MenuItem>
                </Select>
            </FormControl>
        </Filter>
    );
}
