import React, {FC, useEffect, useRef} from "react";
import {FormDialog} from "../../../components/dialogs/FormDialog";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../../helpers/ApiErrorHelper";
import {TextField} from "../../../components/form/TextField";
import NotificationHelper from "../../../helpers/NotificationHelper";
import {useCreateSupplierMutation, useUpdateSupplierMutation} from "../../../redux/api/suppliers";
import {Box, Typography} from "@mui/material";
import {RepeatableField} from "../../../components/form/RepeatableField";
import {MoneyField} from "../../../components/form/MoneyField";
import {SupplierDeliveryTimeTierRequest} from "../../../types/api/requests/supplier/SupplierDeliveryTimeTierRequest";

export type SupplierFormProps = {
    action: 'create' | 'edit',
    open: boolean,
    close: () => void,
    initialValues?: any,
}

export const SupplierForm: FC<SupplierFormProps> = ({action, initialValues, open, close}) => {
    const [createSupplier, {isLoading: isCreating}] = useCreateSupplierMutation();
    const [updateSupplier, {isLoading: isUpdating}] = useUpdateSupplierMutation();
    const dialogRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setTimeout(() => {
            dialogRef.current?.querySelector('.MuiPaper-root')?.scrollTo({top: 0});
        })
    }, [open, dialogRef.current]);


    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        if (values.deliveryTimeTiers !== undefined) {
            values.deliveryTimeTiers = values.deliveryTimeTiers.filter(
                (item: SupplierDeliveryTimeTierRequest) => item.quantity && item.deliveryTimeAddition
            );
        }

        try {
            if (action === 'create') {
                await createSupplier(values).unwrap();
                NotificationHelper.showSuccess('De leverancier is toegevoegd.');
            } else {
                await updateSupplier(values).unwrap();
                NotificationHelper.showSuccess('De leverancier is gewijzigd.');
            }
            close();
        } catch (err) {
            if (err instanceof Object && 'data' in err) {
                const errorData = (err as { data: any }).data;

                if (errorData && errorData['hydra:description']) {
                    NotificationHelper.showError(errorData['hydra:description']);
                } else {
                    ApiErrorHelper.processErrors(err, formikHelpers);
                }
            } else {
                ApiErrorHelper.processErrors(err, formikHelpers);
            }

            formikHelpers.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues: initialValues || { shippingCosts: { amount: null }, noShippingCostsFrom: { amount: null }},
        onSubmit: submit,
        enableReinitialize: true,
    });

    return (
        <FormDialog
            open={open}
            close={close}
            formik={formik}
            ref={dialogRef}
            isSubmitting={isCreating || isUpdating}
            handleSubmit={formik.handleSubmit}
            title={`Leverancier ${action === 'create' ? 'toevoegen' : 'aanpassen'}`}
        >
            <TextField
                required
                fullWidth
                name="name"
                formik={formik}
                label={"Naam"} />
            <TextField
                required
                fullWidth
                name="relationCode"
                formik={formik}
                label={"Relatiecode"} />
            <MoneyField formik={formik} name={'shippingCosts'} label={'Verzendkosten'} />
            <MoneyField formik={formik} name={'noShippingCostsFrom'} label={'Geen verzendkosten vanaf'} />

            <Box sx={{mt: 5}}>
                <Typography variant={"h6"}>Levertijden staffels</Typography>
                <RepeatableField
                    baseName={'deliveryTimeTiers'}
                    formik={formik}
                    columns={[
                        {label: 'Vanaf aantal', name: 'quantity', field: 'text'},
                        {label: 'Levertijd toeslag (in dagen)', name: 'deliveryTimeAddition', field: 'text'},
                    ]}
                />
            </Box>
        </FormDialog>
    );
}
