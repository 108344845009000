import React, {FC, useEffect, useRef} from "react";
import {FormDialog} from "../../../components/dialogs/FormDialog";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../../helpers/ApiErrorHelper";
import NotificationHelper from "../../../helpers/NotificationHelper";
import {
    useCreateCalculationQuestionMutation,
    useUpdateCalculationQuestionMutation
} from "../../../redux/api/calculation-questions";
import {CalculationQuestionResponse} from "../../../types/api/responses/calculation/CalculationQuestionResponse";
import {QuestionForm} from "./QuestionForm";

export type CalculationQuestionFormProps = {
    action: 'create' | 'edit',
    open: boolean,
    close: () => void,
    initialValues?: CalculationQuestionResponse,
}

export const CalculationQuestionForm: FC<CalculationQuestionFormProps> = ({action, initialValues, open, close}) => {
    const [createCalculationQuestion, {isLoading: isCreating}] = useCreateCalculationQuestionMutation();
    const [updateCalculationQuestion, {isLoading: isUpdating}] = useUpdateCalculationQuestionMutation();
    const dialogRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setTimeout(() => {
            dialogRef.current?.querySelector('.MuiPaper-root')?.scrollTo({top: 0});
        })
    }, [open, dialogRef.current]);

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        let hasError = false;

        delete values.calculation;

        try {
            const newValues = {...values};
            newValues.shared = true;

            if (newValues.optionGroup) {
                newValues.optionGroup = newValues.optionGroup.value;
            }

            if (newValues.marginGroup) {
                newValues.marginGroup = newValues.marginGroup.value;
            }

            // Set the sort values
            if (newValues.options) {
                newValues.options = newValues.options.map((option: any, index: number) => {
                    return {...option, sort: index};
                });
            }

            // Submit the form
            if (!newValues['@id']) {
                await createCalculationQuestion(newValues).unwrap();
            } else {
                await updateCalculationQuestion({id: newValues.id, body: newValues}).unwrap();
            }
        } catch (err) {
            hasError = true;
            ApiErrorHelper.processErrors(err, formikHelpers);
            formikHelpers.setSubmitting(false);
            NotificationHelper.showError(`Er is iets misgegaan bij het opslaan van de vraag.`);
        }

        if (!hasError) {
            NotificationHelper.showSuccess('De vraag is bijgewerkt.');
        }

        close();
    };

    const formik = useFormik({
        initialValues: initialValues || {} as CalculationQuestionResponse,
        onSubmit: submit,
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false,
    });

    return (
        <FormDialog
            fullScreen={true}
            open={open}
            close={close}
            formik={formik}
            ref={dialogRef}
            isSubmitting={isCreating || isUpdating}
            handleSubmit={formik.handleSubmit}
            title={`${action === 'create' ? 'Nieuwe' : 'Bewerk'} gedeelde vraag`}
        >
                <QuestionForm
                    formik={formik}
                    fieldPrefix={''}
                    isShared={false}
                    showSharedCheckbox={false}
                />
        </FormDialog>
    );
}
