import React, {FC, useEffect, useState} from "react";
import {useFormik} from "formik";
import {Badge, IconButton, TableCell, TableRow, Tooltip} from "@mui/material";
import {TextField} from "../../../components/form/TextField";
import {SingleImageUploader} from "../../../components/form/SingleImageUploader";
import {CheckboxField} from "../../../components/form/CheckboxField";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ArrayHelper from "../../../helpers/ArrayHelper";
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import {CalculationOptionBlockedForm} from "./CalculationOptionBlockedForm";
import {CalculationOptionPriceTierForm} from "./CalculationOptionPriceTierForm";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import {useDeleteCalculationQuestionOptionMutation} from "../../../redux/api/calculation-question-options";
import {ConfirmDialog} from "../../../components/dialogs/ConfirmDialog";
import {CmsBlockField} from "../../../components/form/CmsBlockField";
import {useGetMagento2AttributesQuery} from "../../../redux/api/magento2";
import ColorSelect from "../../../components/form/ColorSelect";
import {CalculationQuestionOptionResponse} from '../../../types/api/responses/calculation/CalculationQuestionOptionResponse';
import {useParams, useSearchParams} from 'react-router-dom';
import {useGetQuestionQuery} from '../../../redux/api/calculation-questions';
import {useGetQuestionsForCalculationQuery} from '../../../redux/api/calculations';
import {CalculationQuestionsResponse} from '../../../types/api/responses/calculation/CalculationQuestionsResponse';
import {CalculationQuestionResponse} from '../../../types/api/responses/calculation/CalculationQuestionResponse';

export type CalculationQuestionOptionRowProps = {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    rowKey: string,
    questionKey: string,
    questionOptionsKey: string,
    isShared: boolean,
    isOptionGroup?: boolean,
    handlingGroup?: string,
}

export const CalculationQuestionOptionRow: FC<CalculationQuestionOptionRowProps> = ({isShared, isOptionGroup = false, rowKey, questionKey, questionOptionsKey, formik, handlingGroup}) => {
    const isSynced = ArrayHelper.get(formik.values, `${rowKey}.syncWithParent`, false);
    const [deleteCalculationOption] = useDeleteCalculationQuestionOptionMutation();
    const [blockOptionsFormOpen, setBlockOptionsFormOpen] = React.useState(false);
    const [priceTiersFormOpen, setPriceTiersFormOpen] = React.useState(false);
    const rowIndex = parseInt(rowKey.split('.').pop() as string);
    const maxIndex = ArrayHelper.get(formik.values, `${questionKey !== '' ? questionKey+'.' : ''}options`, []).length - 1;
    const [confirmData, setConfirmData] = useState<{title: string, message: string, onConfirm: () => void} | null>(null);
    const {data: magento2Attributes} = useGetMagento2AttributesQuery();

    const {questionId} = useParams();
    const {id} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const key = searchParams.get('key');
    const {data: questions, isLoading: isLoadingQuestions} = useGetQuestionsForCalculationQuery({id: id as unknown as number, filters: {page: 1, pageSize: 1000}});

    const removeRow = async () => {
        setConfirmData({
            title: 'Weet je het zeker?',
            message: 'Weet je zeker dat je deze optie wilt verwijderen? Je kunt deze actie niet ongedaan maken!',
            onConfirm: () => {
                const options = ArrayHelper.get(formik.values, questionOptionsKey, []);
                const removedOptions = options.filter((value: any, index: number) => index === rowIndex);
                const newOptions = options.filter((value: any, index: number) => index !== rowIndex);

                formik.setFieldValue(questionOptionsKey, newOptions);

                if (removedOptions.length > 0) {
                    for(const removedOption of removedOptions) {
                        if (removedOption.id) {
                            deleteCalculationOption(removedOption.id);
                        }
                    }
                }

                setConfirmData(null);
            }
        });
    }


    const moveUp = () => {
        if (0 === rowIndex) return;

        const options = ArrayHelper.get(formik.values, questionOptionsKey, []);
        let newOptions = [...options];

        // Bewaar de oorspronkelijke sort waarden tijdelijk
        const tempSort = newOptions[rowIndex - 1].sort;

        // Update de sort waarden
        newOptions[rowIndex - 1] = {...newOptions[rowIndex - 1], sort: newOptions[rowIndex].sort};
        newOptions[rowIndex] = {...newOptions[rowIndex], sort: tempSort};

        // Wissel de items nu om
        [newOptions[rowIndex - 1], newOptions[rowIndex]] = [newOptions[rowIndex], newOptions[rowIndex - 1]];

        formik.setFieldValue(questionOptionsKey, newOptions);
    };

    const moveDown = () => {
        if (rowIndex >= maxIndex) return; // Check om te voorkomen dat je verder gaat dan het einde van de lijst

        const options = ArrayHelper.get(formik.values, questionOptionsKey, []);
        let newOptions = [...options];

        // Bewaar de oorspronkelijke sort waarden tijdelijk
        const tempSort = newOptions[rowIndex + 1].sort;

        // Update de sort waarden
        newOptions[rowIndex + 1] = {...newOptions[rowIndex + 1], sort: newOptions[rowIndex].sort};
        newOptions[rowIndex] = {...newOptions[rowIndex], sort: tempSort};

        // Wissel de items nu om
        [newOptions[rowIndex], newOptions[rowIndex + 1]] = [newOptions[rowIndex + 1], newOptions[rowIndex]];

        formik.setFieldValue(questionOptionsKey, newOptions);
    };

    const handleDescriptionChange = (e: any) => {
        if ('color' === ArrayHelper.get(formik.values, `${questionKey}.questionType`, '')) {
            formik.setFieldValue(`${rowKey}.sku`, e.target.value.toUpperCase());
        }
    }

    useEffect(() => {
        if (formik.values.questionType === 'color') {
            const productTitle = formik.values.question
            const optionName = ArrayHelper.get(formik.values, `${rowKey}.description`, '');

            const newDescriptionExact = `${productTitle} - ${optionName}`;
            formik.setFieldValue(`${rowKey}.descriptionExact`, newDescriptionExact);
        }
    }, [formik.values.questionType, formik.values.question]);

    useEffect(() => {
        if (isOptionGroup) {
            const options = ArrayHelper.get(formik.values, questionOptionsKey, []);

            options.map((option: CalculationQuestionOptionResponse) => {
                formik.setFieldValue(`${rowKey}.syncWithParent`, true);
            })
        }
    }, [questionOptionsKey, isOptionGroup]);

    //Remove all blocks from items that don't exist
    useEffect(() => {
        let disabledOptions = ArrayHelper.get(formik.values, `${rowKey}.disabledForOptions`, []);
        const optionIds: number[] = [];

        if (questions && questions['hydra:member']) {
            questions['hydra:member'].forEach((question) => {
                if (question.options) {
                    question.options.forEach((option) => {
                        if (typeof option.id === "number") {
                            optionIds.push(option.id);
                        }
                    });
                }
            });
        }

        if (optionIds.length > 0 && disabledOptions.length > 0) {
            const updatedDisabledOptions = disabledOptions.filter((option: number) =>
                optionIds.includes(option)
            );

            if (JSON.stringify(disabledOptions) !== JSON.stringify(updatedDisabledOptions)) {
                formik.setFieldValue(`${rowKey}.disabledForOptions`, updatedDisabledOptions);
            }
        }
    }, [questions, formik.values, rowKey]);

    return (
        <>
            <TableRow sx={{backgroundColor: '#ffffff'}}>
                <TableCell>
                    <Tooltip title={'Geeft aan of deze optie wordt weergegeven in de webshop'}>
                        <span>
                            <CheckboxField formik={formik} name={`${rowKey}.active`} label={' '}/>
                        </span>
                    </Tooltip>
                </TableCell>
                {!isOptionGroup &&
                    <TableCell sx={{background: !isOptionGroup && isSynced ? '#ffeccf' : '#fffffff'}}>
                        <Tooltip title={'Als sync aangevinkt is wordt na het opslaan de data opnieuw gesynchroniseerd'}>
                            <span>
                                <CheckboxField hidden formik={formik} name={`${rowKey}.syncWithParent`} label={' '}/>
                            </span>
                        </Tooltip>
                    </TableCell>
                }
                <TableCell sx={{background: !isOptionGroup && isSynced ? '#ffeccf' : '#fffffff'}}>
                    <TextField required disabled={isSynced && !isOptionGroup} formik={formik} name={`${rowKey}.description`} label={' '} onChange={handleDescriptionChange}/>
                </TableCell>
                <TableCell sx={{background: !isOptionGroup && isSynced ? '#ffeccf' : '#fffffff'}}>
                    <TextField disabled={isSynced && !isOptionGroup} formik={formik} name={`${rowKey}.subDescription`} label={' '} />
                </TableCell>
                <TableCell sx={{background: !isOptionGroup && isSynced ? '#ffeccf' : '#fffffff'}}>
                    <TextField
                        disabled={isSynced && !isOptionGroup}
                        formik={formik}
                        name={`${rowKey}.sku`}
                        label={' '}
                        InputProps={{
                            startAdornment: ArrayHelper.get(formik.values, `${questionKey}.mainSku`, '')
                                ? `${ArrayHelper.get(formik.values, `${questionKey}.mainSku`, '')}-`
                                : '',
                    }} />
                </TableCell>
                <TableCell sx={{background: !isOptionGroup && isSynced ? '#ffeccf' : '#fffffff'}}>
                    <SingleImageUploader disabled={isShared} formik={formik} name={`${rowKey}.image`} label={' '} />
                </TableCell>
                {isOptionGroup || formik.values.questionType === 'color' || ArrayHelper.get(formik.values, questionKey).questionType === 'color' && magento2Attributes != undefined ?
                    <TableCell sx={{background: !isOptionGroup && isSynced ? '#ffeccf' : '#fffffff'}}>
                        <ColorSelect
                            disabled={isSynced && !isOptionGroup}
                            formik={formik}
                            name={`${rowKey}.color`}
                            label={' '}
                        />
                    </TableCell>
                : null}
                <TableCell sx={{background: !isOptionGroup && isSynced ? '#ffeccf' : '#fffffff'}}>
                    <TextField
                        disabled={isSynced && !isOptionGroup}
                        formik={formik}
                        name={`${rowKey}.descriptionExact`}
                        label={' '}/>
                </TableCell>
                <TableCell sx={{background: !isOptionGroup && isSynced ? '#ffeccf' : '#fffffff'}}>
                    <CmsBlockField
                        disabled={isSynced && !isOptionGroup}
                        formik={formik}
                        name={`${rowKey}.informationBlockId`}
                        label={' '}
                    />
                </TableCell>
                <TableCell>
                    <Tooltip title={'Prijs instellingen'}>
                        <span>
                            <IconButton onClick={() => setPriceTiersFormOpen(true)}>
                                <Badge badgeContent={ArrayHelper.get(formik.values, `${rowKey}.priceTiers`, []).length} color="primary">
                                    <PaymentsOutlinedIcon />
                                </Badge>
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell sx={{background: !isOptionGroup && isSynced ? '#ffeccf' : '#fffffff'}}>
                    <TextField disabled={isSynced && !isOptionGroup} formik={formik} name={`${rowKey}.minimumOrderQuantity`} type={'number'} label={' '} />
                </TableCell>
                <TableCell sx={{background: !isOptionGroup && isSynced ? '#ffeccf' : '#fffffff'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Tooltip title={'Geeft aan of deze optie op voorraad is'}>
                            <span>
                                <CheckboxField disabled={isSynced && !isOptionGroup} formik={formik} name={`${rowKey}.inStock`} label={' '}/>
                            </span>
                        </Tooltip>
                        {ArrayHelper.get(formik.values, `${rowKey}.inStock`, false) && (
                            <Tooltip title={'Geeft aan vanaf wanneer deze optie op voorraad is'} placement={'bottom'}>
                                <span>
                                    <DatePicker
                                        disabled={isSynced && !isOptionGroup}
                                        label={'Vanaf'}
                                        value={moment(ArrayHelper.get(formik.values, `${rowKey}.inStockFrom`, moment()))}
                                        onChange={(value) => formik.setFieldValue(`${rowKey}.inStockFrom`, value)}
                                        format={'DD-MM-YYYY'}
                                    />
                                </span>
                            </Tooltip>
                        )}
                    </div>
                </TableCell>
                <TableCell>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Tooltip title={'Blokkeer deze optie'}>
                            <span>
                                <IconButton disabled={questions && questions['hydra:member'].length < 1} onClick={() => setBlockOptionsFormOpen(true)}>
                                    <Badge badgeContent={ArrayHelper.get(formik.values, `${rowKey}.disabledForOptions`, []).length} color="primary">
                                        <BlockOutlinedIcon />
                                    </Badge>
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={'Verwijderen'}>
                            <span>
                                <IconButton disabled={isSynced && !isOptionGroup} onClick={removeRow}>
                                    <DeleteOutlinedIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={'Omhoog verplaatsen'}>
                            <span>
                                <IconButton onClick={moveUp} disabled={0 === rowIndex}>
                                    <ArrowUpwardOutlinedIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={'Omlaag verplaatsen'}>
                            <span>
                                <IconButton onClick={moveDown} disabled={rowIndex == maxIndex}>
                                    <ArrowDownwardOutlinedIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                </TableCell>
            </TableRow>

            {blockOptionsFormOpen && key && questions && (
                <CalculationOptionBlockedForm
                    open={blockOptionsFormOpen}
                    close={() => setBlockOptionsFormOpen(false)}
                    questions={questions}
                    formik={formik}
                    questionKey={key}
                    rowKey={rowKey}
                />
            )}

            {priceTiersFormOpen && (
                <CalculationOptionPriceTierForm
                    open={priceTiersFormOpen}
                    close={() => setPriceTiersFormOpen(false)}
                    formik={formik}
                    questionKey={questionKey}
                    optionKey={rowKey}
                    isSynced={isSynced && !isOptionGroup}
                    handlingGroup={handlingGroup}
                    isOptionGroup={isOptionGroup}
                />
            )}

            {confirmData && (
                <ConfirmDialog
                    title={confirmData.title}
                    message={confirmData.message}
                    onYes={confirmData.onConfirm}
                    onNo={() => setConfirmData(null)}
                />
            )}
        </>
    );
};
