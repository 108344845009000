import React, {FC} from "react";
import {ButtonProps} from "@mui/material/Button/Button";
import {Box, IconButton, Paper, styled} from "@mui/material";
import {ImageResponse} from "../../types/api/responses/image/ImageResponse";
import {useFormik} from "formik";
import {ImageUploadField} from "./ImageUploadField";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {useDeleteImageMutation, useUpdateImageMutation} from "../../redux/api/images";
import ArrayHelper from "../../helpers/ArrayHelper";
import Image from "../ui/Image";

type SingleImageUploader = ButtonProps & {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing,
    name: string,
    label: string,
    isRemovable?: boolean;
}

export const SingleImageUploader: FC<SingleImageUploader> = ({formik, name, label, isRemovable}) => {
    const [updateImage] = useUpdateImageMutation();
    const [deleteImage] = useDeleteImageMutation();

    const image = ArrayHelper.get(formik.values, name);

    const onImageUploaded = (image: ImageResponse) => {
        formik.setFieldValue(name, image);

    }

    const onImageRemoved = async () => {
        await deleteImage(image.id).unwrap();
        formik.setFieldValue(name, null);
    }

    return (
        <>
            {image && (
                <SingleImage key={`image-${image.id}`} variant="outlined">
                    <Image src={image.url} alt={image.name} width={100} height={100} />
                    {isRemovable && (
                        <ButtonHolder>
                            <IconButton onClick={onImageRemoved}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </ButtonHolder>
                    )}
                </SingleImage>
            )}

            <ImageUploadField onUploaded={onImageUploaded} label={image ? 'Wijzigen' : 'Uploaden'} />
        </>

    );
}

const SingleImage = styled(Paper)(({ theme }) => ({
    width: 100,
    marginTop: 0,
    padding: 0,
    position: 'relative',
}));

const ButtonHolder = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    right: 0,
}));

