import React, {FC, useRef, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {PrivateLayout} from "../../components/layout/PrivateLayout";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../components/datagrid/DynamicDataGrid";
import {GridColDef} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import {RowActions} from "../../components/datagrid/RowActions";
import {BooleanRenderer} from "../../components/datagrid/renderers/BooleanRenderer";
import {CalculationQuestionResponse} from "../../types/api/responses/calculation/CalculationQuestionResponse";
import {
    useBulkActivateCalculationQuestionsMutation,
    useBulkDeactivateCalculationQuestionsMutation, useDeleteCalculationQuestionMutation,
    useGetCalculationQuestionsQuery
} from "../../redux/api/calculation-questions";
import {useGetCalculationsQuery} from "../../redux/api/calculations";
import {CalculationQuestionForm} from "./Calculations/CalculationQuestionForm";
import {RequestFilter} from "../../types/api/requests/DynamicListRequest";
import {CalculationQuestionsFilter} from "./Calculations/CalculationQuestionsFilter";
import {AddButton} from "../../components/form/AddButton";
import {ConfirmDialog} from "../../components/dialogs/ConfirmDialog";
import {useAppSelector} from "../../hooks/useAppSelector";
import NotificationHelper from "../../helpers/NotificationHelper";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";


export const CalculationQuestionsPage: FC<{}> = () => {
    const {data: calculations} = useGetCalculationsQuery({});
    const [bulkActivate] = useBulkActivateCalculationQuestionsMutation();
    const [bulkDeactivate] = useBulkDeactivateCalculationQuestionsMutation();
    const [deleteCalculationQuestion] = useDeleteCalculationQuestionMutation();

    const selectedRows = useAppSelector(state => state.datagrid.selected) as Array<number>;

    const [formOpen, setFormOpen] = useState(false);
    const [confirmData, setConfirmData] = useState<{title: string, message: string, onConfirm: () => void} | null>(null);

    const [selectedForEditing, setSelectedForEditing] = useState<CalculationQuestionResponse|null>(null);
    const [filters, setFilters] = useState<RequestFilter>([{field: 'shared', value: ''}]);

    const gridRef = useRef<DynamicDataGridHandle|null>(null);

    const handleDelete = async (id: number) => {
        try {
            await deleteCalculationQuestion(id).unwrap();
            setConfirmData(null);
            gridRef.current?.reload();
            NotificationHelper.showSuccess('De geselecteerde calculatie vraag is verwijderd.');
        } catch (e) {
            NotificationHelper.showError('Er is een fout opgetreden bij het verwijderen van de calculatie vraag. Probeer het later nog eens.');
        }
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1 },
        {
            field: 'question',
            headerName: 'Vraag',
            minWidth: 400,
            flex: 1,
        },
        {
            field: 'questionType',
            headerName: 'Type vraag',
            flex: 1,
        },
        {
            field: 'calculation',
            headerName: 'Calculatie',
            flex: 1,
            renderCell: (params) => {
                return params.row.calculation ? params.row.calculation.sku : null;
            }
        },
        {
            field: 'shared',
            headerName: 'Gedeeld',
            flex: 1,
            renderCell: (params) => {
                return <BooleanRenderer value={params.row.shared} />;
            }
        },
        {
            field: 'active',
            headerName: 'Actief',
            flex: 1,
            renderCell: (params) => {
                return <BooleanRenderer value={params.row.active} />;
            }
        },
        {
            field: 'configurationQuestion',
            headerName: 'Configuratie vraag',
            flex: 1,
            renderCell: (params) => {
                return <BooleanRenderer value={params.row.configurationQuestion} />;
            }
        },
        {
            field: 'stockProduct',
            headerName: 'Voorraad product',
            flex: 1,
            renderCell: (params) => {
                return <BooleanRenderer value={params.row.stockProduct} />;
            }
        },
        {
            field: 'actions',
            headerName: 'Acties',
            flex: 1,
            renderCell: (params) => {
                const actions = [];
                if (true === params.row.shared) {
                    actions.push({
                        icon: <EditIcon/>,
                        label: 'Wijzigen',
                        onClick: () => {
                            setSelectedForEditing(params.row);
                            setFormOpen(true);
                        }
                    });
                }

                if(!params.row.active) {
                    actions.push({
                        icon: <ToggleOnOutlinedIcon/>,
                        label: 'Activeren',
                        onClick: () => {
                            setConfirmData({
                                title: 'Vraag activeren',
                                message: 'Weet je zeker dat je deze vraag wilt activeren?',
                                onConfirm: () => {
                                    handleBulkActivate([params.row.id]);
                                }
                            });
                        }
                    });
                }

                if(params.row.active) {
                    actions.push({
                        icon: <ToggleOffOutlinedIcon/>,
                        label: 'Deactiveren',
                        onClick: () => {
                            setConfirmData({
                                title: 'Vraag deactiveren',
                                message: 'Weet je zeker dat je deze vraag wilt deactiveren?',
                                onConfirm: () => {
                                    handleBulkDeactivate([params.row.id]);
                                }
                            });
                        }
                    });
                }

                if(!params.row.calculation) {
                    actions.push({
                        icon: <DeleteForeverIcon/>,
                        label: 'Verwijderen',
                        onClick: () => setConfirmData({
                            title: 'Calculatievraag verwijderen',
                            message: `Weet je zeker dat je calculatie "${params.row.question ?? params.row.id}" wilt verwijderen?`,
                            onConfirm: async () => {
                                await handleDelete(params.row.id);
                            }
                        })
                    });
                }

                return <RowActions actions={actions}/>;
            },
        },
    ];

    const handleCreateClick = () => {
        setSelectedForEditing(null);
        setFormOpen(true);
    }

    const handleCloseFormDialog = () => {
        setFormOpen(false);
        gridRef.current?.reload();
    }

    const handleBulkActivate = async (ids: Array<number>) => {
        try {
            await bulkActivate(ids).unwrap();
            gridRef.current?.reload();
            setConfirmData(null);
            NotificationHelper.showSuccess('De geselecteerde vragen zijn geactiveerd.');
        } catch (e) {
            NotificationHelper.showError('Er is een fout opgetreden bij het activeren van de geselecteerde vragen. Probeer het later nog eens.');
        }
    }

    const handleBulkDeactivate = async (ids: Array<number>) => {
        try {
            await bulkDeactivate(ids).unwrap();
            gridRef.current?.reload();
            setConfirmData(null);
            NotificationHelper.showSuccess('De geselecteerde vragen zijn gedeactiveerd.');
        } catch (e) {
            NotificationHelper.showError('Er is een fout opgetreden bij het deactiveren van de geselecteerde vragen. Probeer het later nog eens.');
        }
    }

    return (
        <PrivateLayout>
            <Grid container>
                <Grid item xs={12} sm={6} mb={3}>
                    <Typography component="h1" variant="h5">
                        Calculatie vragen
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={'right'}>
                    <AddButton title="Gedeelde calculatie vraag toevoegen" onClick={handleCreateClick} />
                </Grid>

                <CalculationQuestionsFilter filters={filters} onFiltersChanged={setFilters}/>

                <DynamicDataGrid
                    actions={[
                        {
                            icon: <ToggleOnOutlinedIcon />,
                            label: 'Activeren',
                            onClick: () => setConfirmData({
                                title: 'Geselecteerde vragen activeren',
                                message: `Weet je zeker dat je ${selectedRows.length} vragen wilt activeren?`,
                                onConfirm: () => handleBulkActivate(selectedRows),
                            })
                        },
                        {
                            icon: <ToggleOffOutlinedIcon />,
                            label: 'Deactiveren',
                            onClick: () => setConfirmData({
                                title: 'Geselecteerde vragen deactiveren',
                                message: `Weet je zeker dat je ${selectedRows.length} vragen wilt deactiveren?`,
                                onConfirm: () => handleBulkDeactivate(selectedRows),
                            })
                        },
                    ]}
                    ref={gridRef}
                    filter={filters}
                    columns={columns}
                    queryHook={useGetCalculationQuestionsQuery}
                    autoHeight={true}
                    checkboxSelection={true}
                    disableRowSelectionOnClick={true}
                />
            </Grid>

            <CalculationQuestionForm
                action={null === selectedForEditing ? 'create' : 'edit'}
                open={formOpen}
                close={handleCloseFormDialog}
                initialValues={selectedForEditing as CalculationQuestionResponse}
            />

            {confirmData && (
                <ConfirmDialog
                    title={confirmData.title}
                    message={confirmData.message}
                    onYes={confirmData.onConfirm}
                    onNo={() => setConfirmData(null)}
                />
            )}
        </PrivateLayout>
    );
}
