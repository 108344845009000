import React, {FC, SyntheticEvent, useEffect, useState} from "react";
import {Autocomplete, AutocompleteProps, Checkbox, TextField} from "@mui/material";
import {useFormik} from "formik";
import ArrayHelper from "../../helpers/ArrayHelper";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {ConfirmDialog} from '../dialogs/ConfirmDialog';
import DynamicListRequest, {RequestFilter} from "../../types/api/requests/DynamicListRequest";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type AutocompleteOption = {
    value: string,
    label: string,
}

type AutocompleteMultipleFieldBackendProps = Omit<AutocompleteProps<AutocompleteOption, true, false, true> & {
    formik: ReturnType<typeof useFormik> | any,
    name: string,
    label: string,
    onAdd?: (option: AutocompleteOption) => void,
    onRemove?: (option: AutocompleteOption) => void,
    removeConfirmMessage?: string | undefined;
    queryHook: any;
    searchField?: string;
}, 'renderInput' | 'options'>

export const AutocompleteMultipleFieldBackend: FC<AutocompleteMultipleFieldBackendProps> = ({
    formik, 
    name, 
    label, 
    onAdd, 
    onRemove, 
    removeConfirmMessage, 
    queryHook,
    searchField = 'name',
    ...restProps
}) => {
    const [initialSelection, setInitialSelection] = React.useState<Array<string>>([]);
    const [search, setSearch] = useState('');
    const [inputValue, setInputValue] = useState('');
    let currentValues = ArrayHelper.get(formik.values, name);
    const [confirmData, setConfirmData] = useState<{title: string, message: string, onConfirm: () => void, onDecline: () => void} | null>(null);

    const request: DynamicListRequest = {
        search,
        page: 1,
        pageSize: 7,
        sort: [{ field: 'name', sort: 'ASC' }],
    };

    const { data, refetch } = queryHook(request);

    // Haal de geselecteerde optiegroepen op
    const selectedRequest: DynamicListRequest = {
        page: 1,
        pageSize: 100,
        filter: currentValues?.length ? [
            { field: 'id', value: currentValues.map((v: string) => v.split('/').pop()).join(',') }
        ] : undefined
    };

    const { data: selectedData } = queryHook(selectedRequest);

    const options = React.useMemo(() => {
        if (!data?.['hydra:member']) return [];
        return data['hydra:member'].map((item: any) => ({
            value: item['@id'] || item.id,
            label: `${item.name || ''}${item.sku ? ` (${item.sku})` : ''}`
        }));
    }, [data]);

    const selectedOptionsMap = React.useMemo(() => {
        if (!selectedData?.['hydra:member']) return {};
        return selectedData['hydra:member'].reduce((acc: { [key: string]: { name: string, sku: string } }, item: any) => {
            acc[item['@id'] || item.id] = {
                name: item.name || '',
                sku: item.sku || ''
            };
            return acc;
        }, {});
    }, [selectedData]);

    if (currentValues && !(currentValues instanceof Array)) {
        currentValues = [currentValues];
    }

    useEffect(() => {
        if (options.length > 0) {
            setInitialSelection(
                options
                    .filter((option: AutocompleteOption) => currentValues.includes(option.value))
                    .map((option: AutocompleteOption) => option.value)
            );
        }
    }, [options, currentValues]);

    const selectedOptions = React.useMemo(() => {
        if (!currentValues) return [];
        
        return currentValues.map((value: string) => {
            const existingOption = options.find((option: AutocompleteOption) => option.value === value);
            if (existingOption) return existingOption;
            
            // Gebruik de data van de geselecteerde optiegroepen
            const selectedData = selectedOptionsMap[value];
            if (selectedData) {
                return {
                    value: value,
                    label: `${selectedData.name}${selectedData.sku ? ` (${selectedData.sku})` : ''}`
                };
            }
            
            // Fallback als er geen data is
            const id = value.split('/').pop();
            return {
                value: value,
                label: `Optiegroep ${id}`
            };
        });
    }, [options, currentValues, selectedOptionsMap]);

    return (
        <>
            <Autocomplete
                multiple
                disableCloseOnSelect
                options={options}
                isOptionEqualToValue={(option: AutocompleteOption, value: AutocompleteOption) => option.value === value.value}
                filterSelectedOptions={false}
                getOptionLabel={(option: string | AutocompleteOption) => {
                    if (typeof option === 'string') {
                        return options.find((o: AutocompleteOption) => o.value === option)?.label || '';
                    }
                    return option.label;
                }}
                value={selectedOptions}
                inputValue={inputValue}
                onInputChange={(event, newInputValue, reason) => {
                    if (reason === 'reset') {
                        return;
                    }
                    setInputValue(newInputValue);
                    setSearch(newInputValue);
                    setTimeout(() => {
                        refetch();
                    }, 300);
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {typeof option === 'string' ? options.find((o: AutocompleteOption) => o.value === option)?.label : option.label}
                    </li>
                )}
                onChange={(event: SyntheticEvent, value: (string | AutocompleteOption)[], reason: string) => {
                    const selectedValues = value.map(v => typeof v === 'string' ? v : v.value);
                    
                    // Bepaal welke opties zijn verwijderd door te vergelijken met de huidige waarden
                    let removedOptions = currentValues
                        .filter((option: string) => !selectedValues.includes(option))
                        .map((selected: string) => options.find((o: AutocompleteOption) => o.value === selected));

                    // Handle toevoegen van opties
                    if (value) {
                        if (typeof onAdd === 'function') {
                            const addedOptions = value
                                .filter((option: string | AutocompleteOption) => !currentValues.includes(typeof option === 'string' ? option : option.value))
                                .map((selected: string | AutocompleteOption) => options.find((o: AutocompleteOption) => o.value === (typeof selected === 'string' ? selected : selected.value)));
                            addedOptions.forEach((option) => {option && onAdd(option)});
                        }
                    }

                    // Handle verwijderen van opties
                    if (removedOptions.length > 0) {
                        if (removeConfirmMessage) {
                            setConfirmData({
                                title: 'Weet je het zeker?',
                                message: removeConfirmMessage,
                                onConfirm: () => {
                                    if (typeof onRemove === 'function') {
                                        removedOptions.forEach((option: AutocompleteOption | undefined) => {option && onRemove(option)});
                                    }
                                    formik.setFieldValue(name, selectedValues);
                                    setInitialSelection(selectedValues);
                                    setConfirmData(null);
                                    setInputValue('');
                                },
                                onDecline: () => {
                                    setConfirmData(null);
                                }
                            });
                        } else {
                            if (typeof onRemove === 'function') {
                                removedOptions.forEach((option: AutocompleteOption | undefined) => {option && onRemove(option)});
                            }
                            formik.setFieldValue(name, selectedValues);
                            setInputValue('');
                        }
                    } else {
                        formik.setFieldValue(name, selectedValues);
                        setInputValue('');
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={label}
                        placeholder={label}
                    />
                )}
                {...restProps}
            />
            {confirmData && (
                <ConfirmDialog
                    title={confirmData.title}
                    message={confirmData.message}
                    onYes={confirmData.onConfirm}
                    onNo={confirmData.onDecline}
                    html
                />
            )}
        </>
    );
}
