import React, {FC} from "react";
import {AppDialog} from "../../../components/dialogs/AppDialog";
import {useFormik} from "formik";
import {Box, Button, useTheme} from "@mui/material";
import ArrayHelper from "../../../helpers/ArrayHelper";
import {RepeatableField} from "../../../components/form/RepeatableField";
import {MoneyField} from "../../../components/form/MoneyField";
import {PercentageField} from "../../../components/form/PercentageField";
import {useGetMarginGroupsQuery} from "../../../redux/api/margin-groups";
import {
    useDeleteCalculationQuestionOptionPriceTierMutation
} from "../../../redux/api/calculation-question-option-price-tiers";
import {useGetHandlingCostGroupQuery} from "../../../redux/api/handling-cost-groups";
import {useGetCalculationQuery} from "../../../redux/api/calculations";
import {useGetSupplierQuery} from "../../../redux/api/suppliers";

export type CalculationOptionPriceTierFormProps = {
    close: () => void,
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    open: boolean,
    questionKey: string,
    optionKey: string,
    isSynced?: boolean
    handlingGroup?: string,
};

export const CalculationOptionPriceTierForm: FC<CalculationOptionPriceTierFormProps> = ({
    close,
    open,
    formik,
    questionKey,
    optionKey,
    isSynced,
    handlingGroup
}) => {
    const [deletePriceTier] = useDeleteCalculationQuestionOptionPriceTierMutation();
    const theme = useTheme();
    const {data: marginGroups} = useGetMarginGroupsQuery({});
    const {data: optionGroup} = useGetHandlingCostGroupQuery(handlingGroup || '');
    const questionData = ArrayHelper.get(formik.values, questionKey, {});
    const optionData = ArrayHelper.get(formik.values, optionKey, {});
    const minimumMarginAmount = ArrayHelper.get(questionData, 'minimumMargin.amount', -1);
    const {data: calculation} = useGetCalculationQuery(questionData.calculation);
    const {data: supplier} = useGetSupplierQuery(questionData?.calculation?.supplier || "");

    const handlePriceTierRemove = (index: number) => {
        const priceTierToRemove = ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${index}`);
        if (!priceTierToRemove) {
            return;
        }

        deletePriceTier(priceTierToRemove.id);
    }

    const calculateMargin = (row: number, field: string) => {
        const priceTiers = ArrayHelper.get(formik.values, `${optionKey}.priceTiers`, []);

        priceTiers.forEach((priceTier: any, index: number) => {
            if (index !== row) {
                return;
            }

            const priceTierKey = `${optionKey}.priceTiers.${index}`;

            // formik.setFieldValue(`${priceTierKey}.price.amount`, 0);
            // formik.setFieldValue(`${priceTierKey}.marginPercentage`, 0);
            // formik.setFieldValue(`${priceTierKey}.purchasePricePerItem.amount`, 0);
            // formik.setFieldValue(`${priceTierKey}.purchasePricePerItemExclBonus.amount`, 0);
            // formik.setFieldValue(`${priceTierKey}.margin.amount`, 0);
            // formik.setFieldValue(`${priceTierKey}.purchasePricePerItemWithPostage.amount`, 0);

            const quantity = parseInt(ArrayHelper.get(formik.values, `${priceTierKey}.quantity`, 0)) || 0;
            let setupCost = parseFloat(ArrayHelper.get(formik.values, `${priceTierKey}.setupCost.amount`, 0)) || 0;
            let purchasePrice = parseFloat(ArrayHelper.get(formik.values, `${priceTierKey}.purchasePrice.amount`, 0)) || 0;
            let price = parseFloat(ArrayHelper.get(formik.values, `${priceTierKey}.price.amount`, 0)) || 0;
            let marginPercentage = parseFloat(ArrayHelper.get(formik.values, `${priceTierKey}.marginPercentage`, 0)) || 0;

            // Bereken de toeslag p/s (bonusCost) en de verzendkosten (postageCost)
            let bonusCost = handlingGroup && optionGroup ? getBonus(row) || 0 : 0;
            let postageCost = supplier && questionData.questionType === "amount" ? getPostageCost(row) || 0 : 0;

            // Bereken de prijs p/s inclusief toeslag en verzendkosten
            const pricePerItemWithPostage = Math.round((purchasePrice + (setupCost / quantity) + (postageCost / quantity) + bonusCost) * 100) / 100;
            formik.setFieldValue(`${priceTierKey}.purchasePricePerItemWithPostage.amount`, pricePerItemWithPostage);

            // Zorg ervoor dat de toeslag direct bij de prijs p/s wordt opgeteld bij het laden
            if (field === 'quantity' || field === 'setupCost' || field === 'purchasePrice' || field === 'marginPercentage' || field === 'init') {
                if (!isNaN(marginPercentage) && marginPercentage > 0 && quantity > 0) {
                    price = Math.round((purchasePrice + (setupCost / quantity) + (postageCost / quantity) + bonusCost) / (1 - (marginPercentage / 100)) * 100) / 100;
                    formik.setFieldValue(`${priceTierKey}.price.amount`, price);
                }
            } else if (field === 'price' || field === 'init') {
                if (!isNaN(price) && price > 0 && quantity > 0) {
                    marginPercentage = Math.round((1 - ((purchasePrice + (setupCost / quantity) + (postageCost / quantity) + bonusCost) / price)) * 100 * 10) / 10;
                    formik.setFieldValue(`${priceTierKey}.marginPercentage`, marginPercentage);
                }
            }

            // Recalculate other dependent fields
            if (quantity > 0) {
                formik.setFieldValue(`${priceTierKey}.purchasePricePerItem.amount`, Math.round((purchasePrice + (setupCost / quantity) + bonusCost) * 100) / 100);
                formik.setFieldValue(`${priceTierKey}.purchasePricePerItemExclBonus.amount`, Math.round((purchasePrice + (setupCost / quantity)) * 100) / 100);
                formik.setFieldValue(`${priceTierKey}.margin.amount`, Math.round(((purchasePrice + (setupCost / quantity) + (postageCost / quantity) + bonusCost) / (1 - (marginPercentage / 100)) * 0.3) * 100) / 100);
            }
        });
    };

    const getBonus = (row: number) => {
        if (optionGroup) {
            const quantity = ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.quantity`, 0);
            const tiers = optionGroup?.handlingCostGroupTiers ?? [];
            const sortedTiers = [...tiers].sort((a, b) => b.quantity - a.quantity);

            for (const tier of sortedTiers) {
                if (tier.quantity <= quantity) {
                    return tier?.handlingCostPerItem?.amount;
                }
            }
            return 0;
        }
    };

    const getPostageCost = (row: number) => {
        if (questionData.questionType === "amount" && supplier) {
            const quantity = ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.quantity`, 0);
            const price = ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.purchasePrice.amount`, 0);

            if (supplier.noShippingCostsFrom?.amount > (price * quantity)) {
                return supplier.shippingCosts.amount;
            }
            return 0;
        }
    };

    const getTotal = (row: number) => {
        const quantity = ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.quantity`, 0);
        const price = optionData?.priceTiers[row]?.price?.amount;

        if (quantity > 0 && price !== undefined) {
            return Math.round((quantity * price) * 100) / 100;
        }

        return 0;
    }

    return (
        <AppDialog
            open={open}
            onClose={close}
            title={`Staffelprijzen voor optie "${optionData.description || 'Nieuwe optie'}" ${questionData.question ? `van "${questionData.question}"` : ''}`}
            fullScreen={false}
            maxWidth={"lg"}
            fullWidth={true}
        >
            <div style={{marginBottom: theme.spacing(4)}}>
                Stel hieronder de staffelprijzen in voor de optie <strong>{optionData.description}</strong> van de
                vraag <strong>{questionData.question}</strong>.
            </div>

            <RepeatableField
                baseName={`${optionKey}.priceTiers`}
                formik={formik}
                disabled={isSynced}
                onRemove={handlePriceTierRemove}
                columns={[
                    {
                        label: 'Vanaf aantal',
                        name: 'quantity',
                        field: 'number',
                        fieldProps: (row) => ({
                            onBlur: () => calculateMargin(row, 'quantity')
                        })
                    },
                    {
                        label: 'Instel kosten (totaal)',
                        name: 'setupCost',
                        field: MoneyField,
                        fieldProps: (row: number) => ({
                            value: ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.setupCost.amount`, 0),
                            onBlur: () => calculateMargin(row, 'setupCost')
                        })
                    },
                    {
                        label: 'Inkoopprijs (p/s)',
                        name: 'purchasePrice',
                        field: MoneyField,
                        fieldProps: (row) => ({
                            onBlur: () => calculateMargin(row, 'purchasePrice')
                        })
                    },
                    {
                        label: 'Inkoopprijs p/s zonder extra',
                        name: 'purchasePricePerItemExclBonus',
                        field: MoneyField,
                        fieldProps: (row) => ({
                            onBlur: () => calculateMargin(row, 'purchasePricePerItem'),
                            disabled: true
                        })
                    },
                    ...(handlingGroup && optionGroup ? [{
                        label: 'Toeslag p/s',
                        name: 'bonus',
                        field: MoneyField,
                        fieldProps: (row: number) => ({
                            value: getBonus(row),
                            disabled: true
                        })
                    }] : []),
                    ...(handlingGroup && optionGroup ? [{
                        label: 'Prijs p/s + toeslag',
                        name: 'purchasePricePerItem',
                        field: MoneyField,
                        fieldProps: (row: number) => ({
                            disabled: true,
                            onBlur: () => calculateMargin(row, 'purchasePricePerItem')
                        })
                    }] : []),
                    ...(supplier && questionData.questionType === "amount" ? [{
                        label: 'Verzendkosten (totaal)',
                        name: 'postageCost',
                        field: MoneyField,
                        fieldProps: (row: number) => ({
                            value: getPostageCost(row),
                            disabled: true
                        })
                    }] : []),
                    ...(supplier && questionData.questionType === "amount" ? [{
                        label: 'Prijs p/s incl verzendkosten',
                        name: 'purchasePricePerItemWithPostage',
                        field: MoneyField,
                        fieldProps: (row: number) => ({
                            onBlur: () => calculateMargin(row, 'purchasePricePerItemWithPostage'),
                            disabled: true
                        })
                    }] : []),
                    {
                        label: 'Marge %',
                        name: 'marginPercentage',
                        field: PercentageField,
                        fieldProps: (row) => ({
                            required: true,
                            onBlur: () => calculateMargin(row, 'marginPercentage')
                        })
                    },
                    {
                        label: 'Marge EUR',
                        name: 'margin',
                        field: MoneyField,
                        fieldProps: (row) => ({
                            disabled: true,
                            sx: {
                                '& .MuiInput-root': {
                                    padding: '0px 3px',
                                    backgroundColor: ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.margin.amount`, 0) < minimumMarginAmount
                                        ? '#ffc5b8'
                                        : '#efffe6'
                                }
                            }
                        })
                    },
                    {
                        label: 'Prijs (p/s)',
                        name: 'price',
                        field: MoneyField,
                        fieldProps: (row) => ({
                            onBlur: () => calculateMargin(row, 'price')
                        })
                    },
                    {
                        label: 'Prijs (totaal)',
                        name: 'totalPrice',
                        field: MoneyField,
                        fieldProps: (row) => ({
                            value: getTotal(row),
                            onBlur: () => calculateMargin(row, 'totalPrice')
                        })
                    },
                ]}
            />

            <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                <Button color="primary" onClick={() => close()} variant={"contained"}>
                    Akkoord
                </Button>
            </Box>
        </AppDialog>
    );
}
